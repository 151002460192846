<template>
  <div id="navigation">
    <div class="head">
      <div class="headbox logo" @click="roteindex">
        <img :src="hfliat.smallLogo" alt="" style="height: 58px; cursor: pointer" />
      </div>
      <div class="headbox1" @click="roteindex">
        <img :src="hfliat.bigLogo" alt="" class="logo2" style="height: 58px" />
      </div>
      <div class="headbox2" @keyup.enter="serbtn">
        <div class="inpbox">
          <img src="@/assets/image/search.png" alt="" />
          <input type="text" v-model="title" placeholder="请输入关键词" />
          <button @click="serbtn">搜索</button>
        </div>
      </div>
    </div>
    <div class="navbox">
      <div class="nav">
        <router-link to="/home" class="navas" target="_blank">
          <div :class="{ tlisthover: $route.path === '/home' }" class="nav-box bro">首&nbsp;&nbsp;&nbsp;&nbsp;页</div>
        </router-link>
        <!-- <router-link v-for="(item,index) in menuList" :key='index' :to="navUrl" class="navas"  target="_blank"> -->
          <div v-for="(item,index) in menuList" :key='index' class="nav-box navas DropdownTitle" @mouseover="focusMenuName(item)" @click="getMenuName(item)" :class="{ tlisthover: $route.path === navUrl }">
            <el-dropdown class="noDropdown" >
              <div class="nav-box" :class="{ tlisthover: $route.path === navUrl }" >{{item.name}}</div>
                <el-dropdown-menu v-if="showdropdown" slot="dropdown" style="width: 115px; background: #2ea8e1;">
                  <el-dropdown-item class="dropdownitem" v-for="(items,indexs) in item.subCates" :key='indexs' style="color:#fff;">
                    <div @click="getMenuName(items)">
                      <span class="menuss">{{items.name}}</span>
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
          </div>
      </div>
    </div>

    <div class="navmxbox">
      <div class="mxheadbox">
        <!-- 如果为首页的话，隐藏返回上级栏目
              如果为详情页，显示出来
           -->
        <div
          :class="{
            mxfhsyjj: $route.path === '/notann'
          }"
          style="display: none"
        >
          <img
            src="../../assets/image/fh.png"
            @click="fhsyj()"
            class="aactive"
            style="height: 30px; margin-top: 11px; margin-left: 11px; display: none"
          />
        </div>

        <div
          :class="{ mxhboxxx: $route.path === '/notann' }"
          style="display: flex; margin-left: 10px; flex: 4; height: 60px"
        >
          <router-link to="home">
            <img :src="hfliat.mobile_logo" style="height: 36px; margin: 7px 0 5px 0px" alt="" />
          </router-link>
        </div>

        <div class="mxhbox" style="text-align: right; width: 100%">
          <img
            src="@/assets/image/list.png"
            @click="drawer = true"
            style="height: 30px; margin-top: 11px; margin-right: 11px"
          />
        </div>
      </div>
      <el-drawer :visible.sync="drawer" :with-header="false" ltr ref="navDrawer" :modal-append-to-body="false">
        <ul class="ctul">
          <li @click="mentActive">
            <router-link to="/home" class="navas">
              <div class="navBox">首页</div>
            </router-link>
          </li>
          <li @click="mentActive">
            <router-link to="/softoa" class="navas">
              <div class="navBox">通知公告</div>
            </router-link>
          </li>
          <li @click="mentActive">
            <router-link to="/asoser" class="navas">
              <div class="navBox">协会服务</div>
            </router-link>
          </li>
          <li @click="mentActive">
            <router-link to="/assnew" class="navas">
              <div class="navBox">协会快讯</div>
            </router-link>
          </li>
          <li @click="mentActive">
            <router-link to="/concen" class="navas">
              <div class="navBox">党建动态</div>
            </router-link>
          </li>
        </ul>
        <el-collapse accordion>
          <el-collapse-item title="会员风采" name="1">
            <div class="sfqitem" @click="mentActive">
              <router-link to="/famous" class="navas"> 明星企业 </router-link>
            </div>
            <div class="sfqitem" @click="mentActive">
              <router-link to="/character" class="navas"> 人物风采 </router-link>
            </div>
            <div class="sfqitem" @click="mentActive">
              <router-link to="/products" class="navas"> 优质产品 </router-link>
            </div>
          </el-collapse-item>
        </el-collapse>
        <el-collapse accordion>
          <el-collapse-item title="关于协会" name="2">
            <div class="sfqitem" @click="mentActive">
              <router-link to="/assint" class="navas"> 协会简介 </router-link>
            </div>
            <div class="sfqitem" @click="mentActive">
              <router-link to="/associ" class="navas"> 协会章程 </router-link>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import moment from 'moment/moment';
import { detail, news, getMenus } from '@/api/index.js';
// 导出组件
export default {
  name: 'navigation',
  data: function () {
    return {
      isShow: false,
      searchWord: '',
      search: '',
      newssList: [],
      title: '',
      // 头尾部数据
      hfliat: [],
      drawer: false,
      direction: 'rtl',
      inplist: {
        siteId: 2,
        online: 1,
        enabled: 1,
        english: 0,
        title: this.$route.query.title,
        page: 1,
        limit: 10
      },
      menuList:'',
      navUrl:'',
      dropdowmDis:'',
      showdropdown:false,
    };
  },
  computed: {
    isSearch() {
      return this.isFocus;
    }
  },
  created() {
    let url = window.location.href
    if (url.indexOf("?") != -1) { //判断是否有参数
    }else {
      console.log(3636,url);
    }
    this.handleheadfoot();
    this.getMenu()
  },
  methods: {
    navclear() {
      if (this.isShow) {
        this.isShow = !this.isShow;
        document.removeEventListener('navigation', this.hander, false);
      } else {
        this.isShow = !this.isShow;
        document.addEventListener('navigation', this.hander, false);
      }
    },
    getMenu() {
      getMenus({siteId:2}).then((res) => {
        this.menuList = res.data.data
        console.log(99696,this.menuList);
      })
    },
    getMenuName(e) {
      let id = e.id
      // 判断是否有二级菜单
      if(e.subCates == undefined || e.subCates.length == 0 ) {
        if(id=='1625383695511400450') {
          let goUrl = this.$router.resolve({path:'/assint',query: {id:id}})
          window.open(goUrl.href,'_blank')
        }else if(id == '1625383802726199297') {
          let goUrl = this.$router.resolve({path:'/associ',query: {id:id}})
          window.open(goUrl.href,'_blank')
        }else {
          // 一级菜单直接跳转页面
          let goUrl = this.$router.resolve({path:'/details',query: {id:id}})
          window.open(goUrl.href,'_blank')
        }
      }else{
        console.log(111);
      }

    },
    focusMenuName(e) {
      if(e.subCates.length !== 0) {
        this.showdropdown = true
      }else {
        this.showdropdown = false
      }
    },
    hander(e) {
      e.preventDefault();
    },
    handLeclksw() {
      this.isShow = false;
    },
    serbtn() {
      if (this.title == '') {
        this.$message({
          message: '请输入内容',
          type: 'warning'
        });
      } else {
        this.$router.push({ path: '/search', query: { title: this.title } });
        location.reload();
        this.title = '';
        news(this.inplist).then(res => {
          this.serlist = res.data.data.records;
          this.total = res.data.data.total;
          // let title11 = '<span style="color:red">' + this.inplist.title + '</span>';
          this.serlist.forEach(item => {
            item.createTime = moment(item.createTime).format('YYYY年MM月DD日');
            // console.log(item.title.replace(this.inplist.title, title11));
            // return item.title.replace(this.inplist.title, title11);

            // item.title.push({ title: item.title.replace(this.inplist.title, title11) });

            // console.log(item.title);
            // item.title = item.title.replace(this.inplist.title, title11);
            // item.title = '123';
          });
        });
      }
    },
    //头部底部数据
    handleheadfoot() {
      detail(2).then(res => {
        this.hfliat = JSON.parse(res.data.data.config);
        // console.log(this.hfliat);
      });
    },
    roteindex() {
      this.$router.push({ path: '/home/' });
    },
    mentActive() {
      this.$refs.navDrawer.closeDrawer();
    },
    fhsyj() {
      this.$router.go(-1);
    },

  }
};
</script>
<!-- stylus  为设置预编译  scoped   为设置了组件中样式不混淆 -->
<style lang="stylus" scoped>
.el-dropdown-menu__item{
  padding:0 !important;
  text-align: center;
}
.aactive{
  display: inline-block !important
}
.mxhbox{
  flex:1
  height: 60px;
}
.mxhboxx{
  flex:4
  height: 60px;
}
.mxhboxxx{
  justify-content: center;
}
.mxfhsyj{
  display: none
}
.mxfhsyjj{
  display: block !important;
  flex:1
  height: 60px;
}
.inpbox
  border-radius: 8px 12px 9px 8px
  display: flex
  border:1px solid #bbb;
  border-right: 0;
.inpbox >img
  width: 20px;
  height: 20px;
  margin-top: 10px;
  margin-left: 6px;
.inpbox > input
  flex: 8.2;
  padding: 12px 0 11px 6px;
  border:0
  outline: none;
.inpbox > button
  width: 101px
  height: 45px
  border: 1px solid #2ea8e1
  background: #2ea8e1
  border-radius: 0px 8px 8px 0px
  color: #fff
  font-size: 14px
  cursor: pointer
a
	color: #fff
	text-decoration: none
#navigation
	width 100%
	.nav
		max-width 1200px
		background:#2ea8e1
		line-height: 60px
		margin: 5px 0
		.navcol
			border-right:1px solid #fff
			box-sizing border-box
			text-align: center
			color: #fff
			font-size:18px
		.flistcol
			border-left 1px solid #fff
	.head
		max-width:1200px
		margin:0 auto
		display: flex
		@media screen and (max-width:800px)
			display: none
		.headbox
			flex: 2
			margin: 32px 0 29px 0
		.headbox1
			flex: 3
			margin: 37px 0 12px 0
			text-align: center
		.headbox2
			flex: 2
			margin: 40px 0
			.logo2
				margin-left:65px
			.headss
				margin-left:47px
				.inpbox
					border: 1px solid  #eee
					border-radius: 5px
					font-size:12px
					position relative
					.inpss
						border: 0
						padding: 13px 3px 12px 40px
						outline:none;
					.headssbtn
						position: absolute
						left:201px
						top: -2px
					.headssbtn
						width: 100px
						height: 42px
						border: 0
						border-radius: 0 5px 5px 0
						background: #2ea8e1
						margin-top:1px
						// margin-left:41px
	.navbox
		width: 100%
		background #2ea8e1

		.nav
			margin: 0 auto
			display:flex
			.navas
				flex:1
			.nav-box
				height 60px
				line-height 60px
				text-align center
				color #fff
				font-size: 16px
				font-family: Microsoft YaHei
				border-right: 1px solid #fff
				border-image: -webkit-linear-gradient(#2ea8e1 , white , #2ea8e1)0 100 0;
			// .nav-box:hover{
			// 	background: #346cc6
			// }
			.bro
				border-left:1px solid #fff

.mxheadbox
	background: #fff
	display: flex
	position relative
  height: 65px;
  line-height: 65px;
  @media screen and (max-width:800px) {
    border-bottom: 1px solid #f1f1f1;
  }


.nav
	text-align: center
.nav-box:hover
	background: #346cc6
.tlisthover
  	background: #346cc6
.clearnavbox{
	width: 100%
	height: 100%
	background: #2ea8e1
	position fixed
	top:59
	left: 0
	z-index:999
	border: 0
	// display: none
}
.navmxbox
	display: none;



@media (max-width: 1200px)
		a
			display: inline-block
		.headbox > img
			width: 100%
		.headbox1 > img
			width: 100%
		.headssbtn
			display: none
@media (max-width: 800px)
	.head
		display: none
	.navmxbox
		display block
	.nav
		width: 100%
		display: flex
		flex-direction: column
	.navbox
		display: none
.el-dropdown
  width:100%

.el-dropdown-menu__item:not(.is-disabled):hover{
    background-color: #346CC6 !important;
    color: #fff !important;
}
.el-popper /deep/ .popper__arrow {
    border-bottom-color: #1EBEF4 !important;
    left: 50% !important;
    visibility: hidden;
  }

.el-collapse{
  margin-top:15px;
  width:150px !important;
}
.ctul{
  margin:15px 15px 15px 15px ;
  width:150px
}
.ctul >li{
    height: 49px;
    line-height: 49px;
    padding-left: 40px;
    background: #fff;
    color:#303133
    border: 1px solid #ebeef5;
    margin-bottom: 15px;
    border:1px solid #2ea8e1;
}
.ctul >li:hover{
  background:#2ea8e1;
  color:#fff
}
.navBox:hover{
    color:#fff
}
.navas{
  color:#303133
  width:100%
}
.active{
  background: #2ea8e1 !important;
  color: #fff !important;
}
#navigation{
  position:sticky;
  top:-135px;
  z-index:999;
}
.nav-box{
  font-weight: bold;
}
/deep/.el-dropdown-item:hover {
  border: 1px solid red;
  background-color: #fff !important;
  color: #fff;
}
</style>
<style>
.el-drawer {
  width: 180px !important;
}
</style>
