<template>
  <div class="associ">
    <div class="assreboxo">
      <div class="assret">当前位置：<span>关于协会</span>&nbsp;>&nbsp;<span>协会章程</span></div>
    </div>

    <div class="asscont">
      <div class="assbox">
        <div class="assle">
          <div class="assltt">
            <el-menu :default-active="activeNames" :default-openeds="zhankaiNames" background-color="#fff" text-color="#2ea8e1" active-text-color="#fff">
              <Submenu :menuList="menuList"></Submenu>
            </el-menu>

          </div>
        </div>

        <div class="assre">
          <div class="assrebox">
            <div class="assreboxs">
              <div class="asbcimg">
                <img :src="hfliat.content_img_2" alt="" style="width: 300px; height: 225px" />
              </div>
              <div class="asbcwz ql-editor">
                <p v-html="hfliat.statute_content"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { detail,getMenus } from '@/api/index.js';
import Submenu from '@/views/submenu.vue'
export default {
  name: 'associ',
  data() {
    return {
      hfliat: [],
      activelist: 7,
      menuList:'',//侧边栏列表
      navUrl:'',
      activeNames:'',
      zhankaiNames:[],
      menuName:'',
      siteId:'',
      refname:'zhankai',
    };
  },
  //声明传递过来的子组件
  components: {
    Submenu
  },
  created() {
    let url = window.location.href
    if (url.indexOf("?") != -1) { //判断是否有参数
      // let cateid = url.substr(1).split('?')[1].split('&')[0].split('=')[1]
      this.zhankaiNames.push(url.substr(1).split('?')[1].split('&')[0].split('=')[1])
      this.activeNames=url.substr(1).split('?')[1].split('&')[0].split('=')[1]
    }else {
      console.log(3636,url);
    }
    this.handleheadfoot();
    this.getMenu()
  },
  methods: {
    //头部底部数据
    handleheadfoot() {
      detail(2).then(res => {
        this.hfliat = JSON.parse(res.data.data.config);
        // console.log(this.hfliat);
      });
    },
    getMenu() {
      getMenus({siteId:2}).then((res) => {
        this.menuList = res.data.data
      })
    },
    //一级菜单路径
    switchMenu(e) {
      switch(e.name) {
          case '通知公告':
            this.navUrl = '#/softoa'
            break;
          case '协会服务':
            this.navUrl = '#/asoser';
            break;
          case '协会快讯':
            this.navUrl = '#/assnew';
            break;
          case '党建动态':
            this.navUrl = '#/concen';
            break;
          case '关于协会':
            this.navUrl = '#/associ';
            break;
      }
      if(e.subCates.length == 0) {
        window.open(this.navUrl,'_blank')
      }else {
        console.log(111);
      }
    },
    // 二级菜单路径
    switchMenus(e) {
      switch(e) {
          case '明星企业':
            this.navUrl = '/famous';
            break;
          case '人物风采':
            this.navUrl = '/character';
            break;
          case '优质产品':
            this.navUrl = '/products';
            break;
      }
    },
  }
};
</script>
<style lang="stylus" scoped>
.assreboxo
    width:100%;
    margin:0 auto;
    font-family: PingFang SC-Regular
    font-size: 16px;
    // background: #fff
    padding:15px;
    // @media screen and (max-width:800px) {
    //   display: none;
    // }
    .assreboxo
    >span
      color: #1a1a1a
      cursor: pointer;
.associ
  background: #F3F3F3
  .asscont
    max-width: 1200px
    margin: 0 auto
    .assbox
      width: 100%
      display: flex
      .assle
        background: #fff !important;
        // width: 258px !important;
        width:20%;
        .asslto
          width:100%
          border-bottom: 1px solid #dadada
          .asslto
          >span
            font-size:20px
            padding:0 20px 0 20px
            color:#346CC6
            border-bottom:2px solid #346CC6
            margin-top:30px
            display: inline-block;
        .assltt
          width: 100%
          .assltt
          >ul>li
            height: 49px;
            line-height: 49px;
            padding-left: 40px;
            background: #fff;
            border: 1px solid #2ea8e1;
            margin-bottom: 15px;
            margin-left: 15px;
            // color: #7A7A7A
            // width: 80%
            // font-family: Microsoft YaHei
            // margin:15px 0 5px 20px
          .assltt
          >ul>li>a
            color: #303133
            text-decoration: none
           .assltt
          >ul>li>a>div:hover
            color:#fff



      .assre
        // flex 5
        width:80%;
        background: #fff
        .assrebox
          margin:0 20px
          .assreboxo
            margin-top: 20px
            font-family: PingFang SC-Regular
            font-size: 16px
            .assreboxo
            >span
              color: #346CC6
          .assreboxt
            margin-top: 20px
            .asbcont
              margin: 20px 0
          .asbcimg
            width: 300px
            margin 0 auto
            padding:15px 0;
          .asbcwz
            margin: 5px 16px 50px 17px
            font-size:16px
            color:#525252
          .asbpsp
            color: #121212

.assreboxo >span >a
  color: #346cc6;
  text-decoration: none

@media screen and (max-width:800px)
  .Assint
    background: #fff
  .asscont
    max-width: 100%
    margin: 0
  .assle
    display: none

/deep/.el-icon-arrow-right {
  display: none;
}
.sfqitem {
  height: 49px;
  line-height: 49px;
  // padding-left: 45px;
  font-size: 16px;
  color: #303133;
}
.sfqitem:hover {
  cursor:pointer;
  // padding-left: 0px;
  background-color #fff;
}
.zhankai {
  background-color #fff!important;
  color: #303133!important;
}
.el-menu-item.is-active {
  background-color: #2ea8e1!important;
}
.el-menu-item {
  border: 1px solid #2ea8e1;
  margin-bottom: 15px;
}
.assltt>ul>.Submenu>li>.iconfont {
  display: none;
}
</style>
