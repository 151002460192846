import { getReq, postReq } from './network/request.js';

//#####··········GET相关··········#####//
//#####··········POST相关··········#####//
//####········上传········####//
export const news = (data, other) => {
  return postReq('/api/site/news', data, other);
};
// 数据请求
export const newss = id => {
  return getReq(`/api/site/news/${id}`);
};

// 标题与底部数据
export const detail = id => {
  return getReq(`/site/${id}`);
};

// 标题与底部数据
export const columnTitle = id => {
  return getReq(`/api/cate/${id}`);
};

//####········获取栏目········####//
export const getMenus = (data, other) => {
  return postReq('/api/site/menu', data, other);
};