<template>
  <div class="footer">
    <div class="finp">
      <div class="finpbox">
        <ul>
          <li>友情链接</li>
          <li>
            <div class="selectbox" v-html="hfliat.friendshipLink[0]"></div>
          </li>

          <li>
            <div class="selectbox" v-html="hfliat.friendshipLink[1]"></div>
          </li>
          <li>
            <div class="selectbox" v-html="hfliat.friendshipLink[2]"></div>
          </li>
          <li>
            <div class="selectbox" v-html="hfliat.friendshipLink[3]"></div>
          </li>
          <li>
            <div class="selectbox" v-html="hfliat.friendshipLink[4]"></div>
          </li>
        </ul>
      </div>
    </div>
    <div id="fter">
      <div class="fterbox">
        <div class="ftercont">
          <div class="fterccbox">
            <div class="ftercbl">
              <h3 class="bqsy"><span v-html="hfliat.copyright"></span> 版权所有</h3>
              <h3 class="zbdw"><span v-html="hfliat.aq_icp"></span></h3>
              <h3 class="fteh3o">
                主办单位/版权所有:
                <span v-html="hfliat.copyright"></span>

                备案号/经营许可证号:<span v-html="hfliat.aq_icp"></span>
              </h3>

              <h3 v-html="hfliat.address" class="address"></h3>
              <h3 v-html="hfliat.num"></h3>
              <h3 v-html="hfliat.type" class="footlink"></h3>
              <h3 v-html="hfliat.lxwm" class="lxwm"></h3>
            </div>
            <div class="ftercbr">
              <div>
                <img :src="hfliat.qrCode1" style="width: 90px; height: 90px" alt="" />
                <p>微信</p>
              </div>
              <div>
                <img :src="hfliat.qrCode2" style="width: 90px; height: 90px" alt="" />
                <p>微博</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
// 站点统计代码
<script type="text/javascript">
document.write(
  unescape(
    "%3Cspan id='cnzz_stat_icon_1280957526'%3E%3C/span%3E%3Cscript src='https://s9.cnzz.com/z_stat.php%3Fid%3D1280957526%26show%3Dpic2' type='text/javascript'%3E%3C/script%3E"
  )
);
</script>
<script>
import { detail } from '@/api/index.js';
export default {
  name: 'fter',
  data() {
    return {
      hfliat: []
    };
  },
  created() {
    this.handleheadfoot();
  },
  methods: {
    //头部底部数据
    handleheadfoot() {
      detail(2).then(res => {
        this.hfliat = JSON.parse(res.data.data.config);
        console.log(112,this.hfliat);
      });
    }
  }
};
</script>

<style lang="stylus" scoped>
.lxwm{
  display: none;
  @media screen and (max-width: 800px) {
    display:block;
  }
}
.bqsy{
  font-size: 18px !important;
  display: none;
  @media screen and (max-width: 800px) {
    display:block;
  }
}
.address{
  @media screen and (max-width: 800px) {
    display:block !important;
    color:#7a7a7a
  }
}
// .footer
//   min-width:535px;
.footlink
  @media screen and (max-width:850px) {
    display: none;
  }
.finp
  background: #2ea8e1
  height: 40px
  line-height: 40px
  @media screen and (max-width:840px)
    display: none
.finpbox
  max-width: 1050px;
  margin: 0 auto;
  color: #fff
  text-align: center
.finpbox >ul
  display: flex
.finpbox >ul >li
  flex: 1
  margin: 0 15px
  font-size:16px
.finprl
  color:#fff
.fterbox
  max-width: 100%
  min-width: 100%
  background: #2c2c2c
  @media screen and (max-width:800px) {
    background: #f5f5f5
  }
  .ftercont
    max-width: 1200px
    margin: 0 auto
    color: rgba(144,144,144,1)
    .fterccbox
      display: flex
      @media screen and (max-width:670px)
        display: flex;
        flex-flow:column;
        text-align: center;
      .ftercbl
        flex: 5
        text-align: left
        padding: 60px 0 45px 0
        @media screen and (max-width:850px)
          text-align: center
          padding: 30px 0;
          margin:0 15px;
      .ftercbl>h3
        margin: 13px 0 5px 0
        font-size:12px;
        font-weight: 500;
        color:rgba(144,144,144,1)
      .ftercbr
        flex: 2
        text-align: center
        display: flex
        @media screen and (max-width:850px) {
          display: none;
        }
      .ftercbr >div
        margin-right:30px;
        padding: 60px 0 41px 0
.selectbox
  width: 117px;
  color: #7a7a7a;
  height: 25px;
  line-height: 25px;
  margin-top: 7px;
  border-radius: 5px;
  border: 0;
  border: 1px solid #fff;
  background: #fff;
  outline: none;
  font-size:12px
.selectbox>select
  width: 116px;
  color: #7a7a7a;
  border-radius: 5px;
  border: 1px solid #fff;
  outline: none;
.ftercbl >h3:nth-child(3){
  @media screen and (max-width:800px) {
    display:none;
  }
}
.ftercbl >h3:nth-child(2){
  @media screen and (max-width:800px) {
    display:none;
  }
}
.ftercbl >h3:nth-child(1){
  @media screen and (max-width:800px) {
    color:#7a7a7a
  }
}
.fteh3o{
  @media screen and (max-width:800px) {
    margin:0 50px !important;
  }
}
@media screen and (max-width:1050px)
  .finpbox
    width:100%
</style>

<style>
.ftercbl > h3 > a {
  color: rgba(144, 144, 144, 1);
  text-decoration: none;
}
.ftercbl > h3 > span > a {
  color: rgba(144, 144, 144, 1);
  text-decoration: none;
}
@media screen and (max-width: 800px) {
  .ftercbl > h3 > a {
    color: #7a7a7a;
  }
  .ftercbl > h3 > span > a {
    color: #7a7a7a;
  }
}
</style>
<style>
.selectbox > select {
  width: 100px;
  color: #7a7a7a;
  border-radius: 5px;
  border: 0;
}
.selectbox > select > option {
  padding: 0 20px;
}
</style>