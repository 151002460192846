<template>
  <div class="asoser" style="background: #f3f3f3">
    <div class="assreboxo">
      <div class="assret">当前位置：<span>协会11快讯</span></div>
    </div>
    <div class="asmbox">
      <div class="assle">
        <div class="assleft">
          <div class="assltt">
             <el-collapse v-for="(item,index) in menuList" :key="index" >
                <div @click.stop="switchMenu(item)">
                  <el-collapse-item :title="item.name" :name="item.id" >
                    <div class="sfqitem active" v-for="(items,indexs) in item.subCates" :key="indexs" style="display:block" @click.stop="switchMenus(items.name)">
                      <router-link :to="navUrl" class="" style="color: #fff" target="_blank"> {{items.name}} </router-link>
                    </div>
                  </el-collapse-item>
                </div>
              </el-collapse>

          </div>
        </div>
      </div>
      <div class="assre">
        <div class="assrebox">
          <div class="assreboxt"></div>
          <div class="conulbox">
            <ul>
              <li v-for="item in newList" v-bind:key="item.id" @click="handlelitxq(item.id)">
                <div style="display: flex">
                  <!-- <div :class="item.istop ? 'litit1 one-line' : 'litit one-line'" v-html="item.title"></div> -->
                  <div class="litit one-line">
                    <img src="../assets/image/wzzd.png" style="width: 12px" alt="" v-if="item.istop" />
                    <span>{{ item.title }}</span>
                  </div>
                  <!-- <div class="zdspan" v-if="item.istop">置顶</div> -->
                  <div class="wztime">{{ item.createTime }}</div>
                </div>

                <!--summary  -->
                <div class="licont two-line" v-if="item.summary">{{ item.summary }}</div>
                <div class="licont two-line" v-else>{{ item.content | ellipsis }}</div>
              </li>
            </ul>
          </div>

          <div class="seablym">
            <!--@size-change=  pageSize 改变时会触发 -->
            <!-- @current-change=  currentPage 改变时会触发 -->
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="form.page"
              :page-size="15"
              layout="prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
          <div class="seabxxfy">
            <el-pagination
              small
              layout="prev, pager, next"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="form.page"
              :page-size="15"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment/moment';
import { news,getMenus } from '@/api/index.js';
import { $fmtTime } from '@/utils/utils.js';
export default {
  name: 'asoser',
  filters: {
    ellipsis(value) {
      // 正则去除html标签
      if (value.match(/[\u4e00-\u9fa5]/g)) {
        value = value.match(/[\u4e00-\u9fa5]/g).join('');
      } else {
        return;
      }
      // 不为空将显示字数小于90个
      if (value.length > 100) {
        return value.slice(0, 100) + '...';
      }
      return value;
    }
  },
  data() {
    return {
      form: {
        siteid: 2,
        cateid: 3,
        online: 1,
        enabled: 1,
        page: 1,
        limit: 15
      },
      // istop: 0,
      newList: [],
      zdnewList: [],
      total: 0, //总页数，
      urlname: this.$route.path,
      title: document.title,
      menuList:'',//侧边栏列表
      navUrl:'',
      activeNames:'',
    };
  },
  computed: {
    time() {
      return $fmtTime;
    }
  },

  components: {},
  created() {
    this.getList();
    this.getMenu()
  },
  mounted() {},
  methods: {
    getList() {
      news(this.form).then(res => {
        this.newList = res.data.data.records;
        this.total = res.data.data.total;
        this.newList.forEach(item => {
          item.createTime = moment(item.createTime).format('YYYY-MM-DD');
          // let content = richText.replace(/<.>/g, ' item.content');

          // console.log(content);
        });
        // this.istop = this.newList.istop;
        // console.log(this.newList);
      });
    },
    getMenu() {
      getMenus({siteId:2}).then((res) => {
        this.menuList = res.data.data
      })
    },
    //一级菜单路径
    switchMenu(e) {
      switch(e.name) {
          case '通知公告':
            this.navUrl = '#/softoa'
            break;
          case '协会服务':
            this.navUrl = '#/asoser';
            break;
          case '协会快讯':
            this.navUrl = '#/assnew';
            break;
          case '党建动态':
            this.navUrl = '#/concen';
            break;
          case '关于协会':
            this.navUrl = '#/associ';
            break;
      }
      if(e.subCates.length == 0) {
        window.open(this.navUrl,'_blank')
      }else {
        console.log(111);
      }
    },
    // 二级菜单路径
    switchMenus(e) {
      switch(e) {
          case '明星企业':
            this.navUrl = '/famous';
            break;
          case '人物风采':
            this.navUrl = '/character';
            break;
          case '优质产品':
            this.navUrl = '/products';
            break;
      }
    },
    handlelitxq(id) {
      let url = this.$router.resolve({ path: 'notann', query: { id: id, url: this.urlname, title: this.title } });
      window.open(url.href,'_blank')
    },
    handleSizeChange(val) {
      this.form.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.getList();
    }
  }
};
</script>
<style lang="stylus">
.assret
  max-width:1170px;
  background: #fff;
  padding:15px;
  margin:0 auto;
.assreboxo
    width:100%;
    margin:0 auto;
    font-family: PingFang SC-Regular
    font-size: 16px;
    padding:0 !important
    background:none Im !important;
    // background: #fff
    // padding:15px;
    @media screen and (max-width:800px) {
      display: none;
    }
    .assreboxo
    >span
      color: #1a1a1a
      cursor: pointer;
.zdspan
  border: 1px solid #2ea8e1;
  font-size: 14px;
  width: 39px;
  height: 22px;
  display: inline-table;
  line-height: 22px;
  text-align: center;
  margin-left: 5px;
  color:#2ea8e1
a
  text-decoration: none
.asmbox
  max-width: 1200px
  margin: 0 auto
  display: flex
  min-height:900px;
 .assle
        background:#fff !important
        // width:258px !important
        // flex:2
        width:25% !important;

        // .assltt
        //   // width: 69%
        //   .assltt
        //   >ul>li
        //     color:303133
        //     // width: 100%
        //     font-family: Microsoft YaHei
        //   .assltt
        //   >ul>li>a
        //     color: assltt
    .assre
        // flex 5.5 !important
        // max-width:970px;
        width: 75%
        // min-width:535px;
        background: #fff;
        @media screen and (max-width:800px) {
          width:100% !important;
        }
        .assrebox
          margin:0 20px
          .assreboxo
            margin-top: 20px
            margin-top: 20px
            @media screen and (max-width:800px) {
              display: none !important;
            }
            .assreboxo
            >span
              color: #1a1a1a !important
    .conulbox
      margin-bottom:30px
    .conulbox >ul >li
      font-size 14px
      padding:15px 15px 0px 15px !important;
      margin:0 !important;
      // padding-bottom: 10px;
      border-bottom: 1px dashed #C1C1C1;
      cursor: pointer;
    .conulbox >ul >li
      color:#9a9a9a
    .litit1
      font-size: 16px;
      color: #2ea8e1;
      width: 95%;
      font-weight: bold
      // @media screen and (max-width:550px) {
      //   width:74%;
      // }
      // @media screen and (max-width:300px) {
      //   width:74%;
      // }
    .wztime
      float: right;
      color: #8d8d8d;
      font-size: 13px;
      text-align: right;
      width: 127px
      @media screen and (max-width:800px) {
        display:none
      }
      // @media screen and (max-width:610px) {
      //   width:130px;
      // }
      // @media screen and (max-width:550px) {
      //   width:135px;
      // }
    .licont1
      margin: 13px 0
      font-size:15px
      color: #af0505
    .litit
      color: #1a1a1a !important
      width: 85%;
      font-weight: 600;
      font-size: 16px;
      float: left;
      @media screen and (max-width:800px) {
        width:100%;
      }
      // @media screen and (max-width:550px) {
      //   width:74%;
      // }
      // @media screen and (max-width:300px) {
      //   width:74%;
      // }
    .litit >span
      // float: right
      font-size 16px
    .litit >span:hover
      color:#2EA9E1

    .licont
      max-width:872px;
      margin: 10px 0 15px 0;
      font-size:15px;
      color: #7a7a7a;


  .seablym
    margin: 50px 80px 50px 60px
    color: #525252
    text-align: center
    @media screen and (max-width:610px) {
        display: none;
    }
  .seablym>span
    border: 1px solid #C4C4C4
    padding: 5px 13px
    font-size:13px
  .seabxxfy{
    text-align: center
    display: none;
    margin: 50px 80px 50px 60px;
    @media screen and (max-width:610px) {
        display: block;
    }
  }
  .clkcor
    color: #fff
    background: #4088FF
    border: 0
  .fistsp
    color: #C4C4C4
    margin-left: 13px
  .clkinp
    margin: 0 5px
  .clkinp >input
    width: 22px
    height: 20px
    border: 0
    outline: none;

.seasj
  text-align: center
  color: #525252;
  margin-bottom: 15px
  display: none
.seasj>span
  border: 1px solid #c4c4c4;
  padding: 5px 13px;
  font-size: 13px;

.assreboxo >span >a
  color: #346cc6;
  text-decoration: none

@media screen and (max-width:800px)
  .Assmem
    background:#fff
  .asmbox
    display: block
  .assle
    display: none
  .assre
    width: 100%
    background: #fff
    .assrebox
      margin: 0

  .seasj
    display: block
@media screen and (max-width: 600px)
   .seablym
    margin: 15px 0;
    text-align: center



.el-collapse
  border:1px solid #EBEEF5
  margin-left: 15px
.assleft
  // width:257px;
  position: sticky;
  top: 88px;
.el-collapse-item__header
  font-size: 16px;
  padding-left: 40px;
  border: 1px solid #2ea8e1
.el-collapse-item__header:hover
  background:#2ea8e1
  color: #fff
.el-collapse-item__wrap
  border: 1px solid #2ea8e1
  border-top:0
.el-collapse-item__content
  padding-bottom:0px;
.sfqitem >a
  display: block;
  height:49px;
  line-height:49px;
  padding-left: 45px;
  font-size: 16px;
  color: #303133
.sfqitem > a:hover
  display: block
  background:#2ea8e1
  padding-left: 45px;
  color: #fff
  cursor: pointer;
.assltt
  width: 100%;
  margin-top: 18px;
  background: #fff;
.assltt
  >ul>li
    height: 49px;
    line-height: 49px;
    padding-left:40px;
    background: #fff;
    border: 1px solid #2ea8e1;
    margin-bottom: 15px
    margin-left: 15px;
    // border-bottom: 1px solid #EBEEF5;
.assltt
  >ul>li:hover
    background: #2ea8e1;
.assltt
  >ul>li>a:hover
    color: #fff
.assltt
  >ul>li>a
    color: #303133
.assltt
  >ul>li:nth-child(1){
    border-radius: 5px 5px 0 0;
  }
.assltt
  >ul>li:nth-child(6){
    border-radius: 0 0 5px 5px ;
}
.active{
  background: #2ea8e1 !important;
  color:#fff !important
}
.el-collapse{
  margin-bottom: 15px;
}
.el-pager li{
  font-weight: normal;
}



i {
  display: none;
}
.sfqitem {
  height: 49px;
  line-height: 49px;
  // padding-left: 45px;
  font-size: 16px;
  color: #303133;
}
.sfqitem:hover {
  cursor:pointer;
  // padding-left: 0px;
  background-color #fff;
}
</style>
