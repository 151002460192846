//#####··········表单验证··········!//
//####········校验数字的表达式········####//
const number = {
  // 数字
  0: /^[0-9]*$/,
  // n位的数字
  1: /^\d{n}$/,
  // 至少n位的数字
  2: /^\d{n,}$/,
  // m-n位的数字
  3: /^\d{m,n}$/
};

//####········校验字符串的表达式········####//
const string = {
  // 汉字
  0: /^[\u4e00-\u9fa5]{0,}$/,
  // 由数字、26个英文字母或者下划线组成的 3-20 位字符串
  1: /^\w{3,20}$/
};

//####········校特殊的表达式········####//
const other = {
  // Email地址
  0: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
  // 域名
  1: /^(13[0-9]|14[5|7]|15[0|1|2|3|4|5|6|7|8|9]|18[0|1|2|3|5|6|7|8|9])\d{8}$/,
  // 身份证号(15位、18位数字)，最后一位是校验位，可能为数字或字符X
  2: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
  // 强密码(必须为大小写字母和数字的组合，不能使用特殊字符，长度在 8-10 之间)
  3: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9]{8,16}$/,
  // 强密码(必须包含大小写字母和数字的组合，可以使用特殊字符，长度在8-10之间)
  4: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,10}$/
};
export function test(str, reg, index) {
  if (reg == 'num') return number[index].test(str);
  if (reg == 'str') return string[index].test(str);
  if (reg == 'other') return other[index].test(str);
}
export function $fmtTime(date, fmt = 'YYYY-MM-DD hh:mm:ss') {
  date = new Date(date);
  let ret,
    week = ['日', '一', '二', '三', '四', '五', '六'];
  const opt = {
    'Y+': date.getFullYear().toString(),
    'M+': (date.getMonth() + 1).toString(),
    'D+': date.getDate().toString(),
    'h+': date.getHours().toString(),
    'm+': date.getMinutes().toString(),
    's+': date.getSeconds().toString(),
    w: week[date.getDay()],
    n: new Date(date).valueOf()
  };
  for (let k in opt) {
    ret = new RegExp(`(${k})`).exec(fmt);
    if (ret) {
      fmt = fmt.replace(ret[1], ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0'));
    }
  }
  return fmt;
}
