import axios from 'axios';

//#####··········域名管理··········#####//

const server = axios.create({
  //   公司内部(开发)
  // baseURL: 'http://172.16.20.105:8080',
  // 测试
  // baseURL: 'http://125.70.9.252:8089',
  // 生产服务器
  // baseURL: 'http://125.70.9.252:8088',
  baseURL: 'https://api.chinasoft.org.cn',

  time: 10000
});

//#####·········配置默认请求··········#####//
//####········GET请求········####//
/**
 * @description: GET请求封装
 * @param {String} url /接口地址
 * @param {Object} params 请求参数
 * @param {Object} other header等其他属性存放
 * @return {Promise} 接口请求
 */
export function getReq(url, params, other = {}) {
  return server({ method: 'GET', url, params, ...other });
}

//####········POST请求········####//
/**
 * @description: POST请求封装
 * @param {String} url /接口地址
 * @param {Object} data 请求参数
 * @param {Object} other header等其他属性存放
 * @return {Promise} 接口请求
 */
export function postReq(url, data, other = {}) {
  return server({ method: 'POST', url, data, other });
}

//#####·········拦截器··········#####//
//####·······请求拦截器········####//
server.interceptors.request.use(config => {
  config.headers.token = localStorage.getItem('token');
  return config;
});
//####·······响应拦截器········####//
server.interceptors.response.use(res => {
  return res;
});
