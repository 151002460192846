<template>
  <div class="Submenu">
    <template v-for="item in menuList" >
      <!-- 如果当前有子菜单，则显示 el-submenu ，在el-subment 里调用 递归组件 -->
      <el-submenu
        v-if="item.subCates && item.subCates.length > 0"
        :index="item.id"
        :key="item.id"
        @click="switchMenu(item.id)">
        <template slot="title">
          <span slot="title">
            {{ item.name }}
          </span>
        </template>
        <!-- 调用自身  此处是重点-->
        <Submenu :menuList="item.subCates"></Submenu>
      </el-submenu>
      <!-- 如果没有子菜单，则显示当前内容 -->
      <el-menu-item v-else :index="item.id" :key="item.id" @click="switchMenu(item.id)">
        <span v-show="item.subCates == undefined" class="iconfont icon-zuzhizhankai"></span>
        {{ item.name }}
      </el-menu-item>
    </template>
  </div>
</template>
<script>
export default {
  name: 'Submenu',
  data () {
    return {
    }
  },
  props: {
    menuList: {  // 在这里增加了引入校验，方便错误排除
      type: Array,
      required: false,
    },
  },
  methods: {
    switchMenu(e) {
      let id = e
      if(id=='1625383695511400450') {
          console.log(1111111);
          let goUrl = this.$router.resolve({path:'/assint',query: {id:id}})
          window.open(goUrl.href,'_blank')
      }else if(id == '1625383802726199297') {
          let goUrl = this.$router.resolve({path:'/associ',query: {id:id}})
          window.open(goUrl.href,'_blank')
      }else {
          // 一级菜单直接跳转页面
          let goUrl = this.$router.resolve({path:'/details',query: {id:id}})
          window.open(goUrl.href,'_blank')
          console.log(55555);
      }
      // let goUrl = this.$router.resolve({path:'/details',query: {id:id}})
      // window.open(goUrl.href,'_blank')
    },
  },
  created () {
    console.log(7878,this.menuList);
  },
  mounted () {
  },
}
</script>
<style lang="less" >
.el-menu-item,
.el-submenu__title {
  font-size: 17px;
}
.el-menu-item:last-child {
  margin-top: 15px!important;
}
.el-submenu {
  border:1px solid #2ea8e1;
  margin-top: 15px!important;
}
.el-submenu>ul>.Submenu>li {
  border: none;
  background-color: #2ea8e1;
}
</style>
