<template>
  <div id="home">
    <div class="widmar">
      <!-- style="min-width: 535px"-->
      <!-- 通知公告 -->
      <div class="notice">
        <div class="noboxle">
          <LybSwiper id="a" v-if="hfliat.swipeImgList_tzgg">
            <div class="swiper-slide" v-for="(item, index) in hfliat.swipeImgList_tzgg" v-bind:key="index">
              <a :href="item.link">
                <img :src="item.src" alt="" />
                <p v-if="item.title" class="lbone-line" v-html="item.title"></p>
              </a>
            </div>
          </LybSwiper>
        </div>
        <div class="noboxre" >
          <div class="nobtop">
            <span class="nobtoptit">{{columnName1}}</span><span class="sspanafbox"></span
            ><span class="nobtoprou">
              <router-link :to="{path:'/details',query:{id:1}}" target="_blank"> 更多>></router-link>
            </span>
          </div>
          <div class="nobcont">
            <ul>
              <li
                v-for="item in nobList.slice(0, 8)"
                v-bind:key="item.id"
                :title="item.title"
              >
              <router-link class="routerbox" :to="{path:'/notann',query:{id:item.id}}" target="_blank">
                  <img
                    src="../assets/image/wzzd.png"
                    style="width: 16px; height: 16px; margin-top: 3px"
                    alt=""
                    v-if="item.istop"
                  />
                  <div class="one-line" v-html="item.title" v-bind:class="{topnews:item.istop}" style="width: 100%"></div>

                  <div style="width: 110px" class="npbtime">
                    {{ item.createTime }}
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- 协会服务 -->
      <div class="assint">
        <div class="assibox">
          <div class="asspan">{{columnName2}}</div>
          <span class="sspanafbox"></span>
          <span class="asspantwo">
            <router-link :to="{path:'/details',query:{id:2}}" target="_blank"> 更多>></router-link>
          </span>
        </div>
        <div class="asscont1">
          <a :href="item.link" v-for="(item, index) in hfliat.services" v-bind:key="index" target="_blank">
            <img :src="item.src" style="width: 130px" />
          </a>
        </div>
      </div>

      <!-- 协会快讯 -->
      <div class="assint">
        <div class="assibox">
          <div class="asspan">{{columnName3}}</div>
          <span class="sspanafbox"></span>
          <span class="asspantwo">
            <router-link :to="{path:'/details',query:{id:3}}" target="_blank"> 更多>></router-link>
          </span>
        </div>
        <div class="asscont2">
          <div class="acr2">
            <ul class="acrul">
              <li v-for="(item1,index) in assList.slice(0, 4)" v-bind:key="item1.id" >
            <router-link class="routerbox" :to="{path:'/notann',query:{id:item1.id}}" target="_blank">
                <div class="acrdone">
                  <img
                    :src="xhkxfmimg[index] == '' ? require('../assets/image/39.png') : xhkxfmimg[index]"
                    style="width: 92px; height: 60px"
                    alt=""
                  />
                </div>
                <div class="acrdtwo" :title="item1.title">
                  <img
                    src="../assets/image/wzzd.png"
                    style="width: 15px; height: 15px; margin-top: 3px"
                    alt=""
                    v-if="item1.istop"
                  />
                  <span>{{ item1.title }}</span>
                </div>
                <div class="acrtime">{{ item1.createTime }}</div>
                </router-link>
              </li>
            </ul>
          </div>
          <div class="acl2">
            <ul class="aclul">
              <li
                v-for="item1 in assList.slice(4, 11)"
                v-bind:key="item1.id"
                @click="handlelitxq(item1.id)"
                :title="item1.title"
              >
            <router-link class="routerbox" :to="{path:'/notann',query:{id:item1.id}}" target="_blank">
                <div class="one-line" style="width: 100%">
                  <img
                    src="../assets/image/wzzd.png"
                    style="width: 15px; height: 15px; margin-top: 3px"
                    alt=""
                    v-if="item1.istop"
                  />
                  <span>{{ item1.title }}</span>
                </div>

                <div class="acltime">{{ item1.createTime }}</div>
            </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <!-- 党建动态 -->
      <div class="assint">
        <div class="assibox">
          <div class="asspan">{{columnName4}}</div>
          <span class="sspanafbox"></span>
          <span class="asspantwo">
            <router-link :to="{path:'/details',query:{id:4}}" target="_blank"> 更多>></router-link>
          </span>
        </div>
        <div class="asscont">
          <div class="asscol">
            <ul v-if="jdfcList.length">
              <li
                class="jdlist"
                v-for="(item2, index) in jdfcList.slice(0, 5)"
                v-bind:key="index"
                @click="handlelitxq(item2.id)"
              >
            <router-link class="routerbox" :to="{path:'/notann',query:{id:item2.id}}" target="_blank">
                <div class="jdimg">
                  <img
                    :src="djfcfmimg[index] == '' ? require('../assets/image/39.png') : djfcfmimg[index]"
                    style="width: 92px; height: 60px"
                    alt=""
                  />
                </div>
                <div class="jdtit two-line" :title="item2.title">
                  <img
                    src="../assets/image/wzzd.png"
                    style="width: 15px; height: 15px; margin-top: 3px"
                    alt=""
                    v-if="item2.istop"
                  />
                  <span>{{ item2.title }}</span>
                </div>
            </router-link>
              </li>
            </ul>
          </div>
          <div class="assrol">
            <LybSwiper :isShowdot="false" id="b" v-if="hfliat.swipeImgList_djjn && hfliat.swipeImgList_djjn.length">
              <div
                :isShowdot="false"
                class="swiper-slide"
                v-for="item1 in hfliat.swipeImgList_djjn"
                v-bind:key="item1.id"
              >
                <a href="item.link">
                  <img :src="item1.src" alt="" />
                  <p class="swiperpt">
                    <span class="lbone-line">{{ item1.title }}</span>
                  </p>
                </a>
              </div>
            </LybSwiper>
          </div>
        </div>
      </div>

      <!-- 会员风采 -->
      <div class="assint">
        <div class="assibox">
          <div class="asspan">{{columnName5}}</div>
          <span class="sspanafbox"></span>
          <span class="asspantwo">
            <router-link :to="{path:'/details',query:{id:5000}}" target="_blank"> 更多>></router-link>
          </span>
        </div>
        <div class="conent3" style="margin-top: 20px">
          <div class="cont3box">
            <vueSeamlessScroll :data="hyfcList.slice(0, 10)" :class-option="optionCustomer" class="seamless-warp">
              <div class="assintwzbox">
                <div
                  v-for="(item, index) in hyfcList.slice(0, 10)"
                  v-bind:key="index"
                  @click="handlelitxq(item.id)"
                  class="assintwz"
                >
                  <router-link :to="{ path: '/notann', query: { id: item.id } }">
                  <div>
                    <img
                    :src="hyfcfmimg[index] == '' ? require('../assets/image/39.png') : hyfcfmimg[index]"
                    style="width: 223px; height: 145px"
                    :title="item.title"
                      :data-id="item.id"
                      :data-index="index"
                    alt=""
                  />
                  </div>
                    <p class="two-line" v-html="item.title" :title="item.title"></p>
                  </router-link>
                </div>
              </div>
            </vueSeamlessScroll>
          </div>
        </div>
        <div class="conent3" style="margin-top: 20px">
          <div class="cont3box">
            <vueSeamlessScroll :data="hyfcList.slice(10,20)" :class-option="optionCustomer" class="seamless-warp">
              <div class="assintwzbox">
                <div
                  v-for="(item, index) in hyfcList.slice(10, 20)"
                  v-bind:key="index"
                  @click="handlelitxq(item.id)"
                  class="assintwz"
                >
                  <router-link :to="{ path: '/notann', query: { id: item.id } }">
                  <div>
                    <img
                    :src="hyfcfmimg[index+10] == '' ? require('../assets/image/39.png') : hyfcfmimg[index+10]"
                    style="width: 223px; height: 145px"
                    :title="item.title"
                      :data-id="item.id"
                      :data-index="index"
                    alt=""
                  />
                  </div>
                    <p class="two-line" v-html="item.title" :title="item.title"></p>
                  </router-link>
                </div>
              </div>
            </vueSeamlessScroll>
          </div>
        </div>
      </div>

      <!-- 协会介绍 -->
      <div class="assint">
        <div class="assibox">
          <div class="asspan">协会介绍</div>
          <span class="sspanafbox"></span>
          <span class="asspantwo">
            <router-link to="/assint" target="_blank"> 更多>></router-link>
            <!-- <router-link :to="{path:'/details',query:{id:6}}" target="_blank"> 更多>></router-link> -->
          </span>
        </div>

        <div class="asscont">
          <div class="acl">
            <img :src="hfliat.content_img_1" alt="" style="width: 100%;" />
          </div>
          <div class="acr">
            <h3>成都市软件行业协会</h3>
            <div class="assintimg" style="text-align: center;width: 100%;">
              <img :src="hfliat.content_img_1" ref='ImgRefs' alt="" style=" object-fit: cover;width: 100%;" />
            </div>
            <div class="assContent ql-editor" v-html="hfliat.intro_content"></div>
            <router-link to="/assint" target="_blank" style="color:hwb(0deg 45% 55%);font-size:12px;float:right;"> 更多>></router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="hddbbox">
      <div class="lxwmbox" @mouseover="mouseOvero" @mouseout="mouseouto">
        <div style="height: 1rem; font-weight: bold">联系</div>
        <div style="height: 1rem; font-weight: bold">我们</div>
        <div class="lxwmlist" v-show="isshow" @mouseout="mouseoutt">
          <!-- <h3>联系我们</h3> -->
          <ul class="lxwmul">
            <li>
              <div>{{ hfliat.contactUs1 }}</div>
              <div>{{ hfliat.zh }}</div>
            </li>
            <li>
              <div>{{ hfliat.contactUs2 }}</div>
              <div>{{ hfliat.hygz }}</div>
            </li>
            <li>
              <div>{{ hfliat.contactUs3 }}</div>
              <div>{{ hfliat.sjtj }}</div>
            </li>
            <li>
              <div>{{ hfliat.contactUs4 }}</div>
              <div>{{ hfliat.hz }}</div>
            </li>
            <li>
              <div>{{ hfliat.contactUs5 }}</div>
              <div>{{ hfliat.xc }}</div>
            </li>
          </ul>
        </div>
      </div>
      <div class="gfwxbox" @mouseover="mouseOver" @mouseout="mouseoutot">
        <div style="height: 1rem; font-weight: bold">官方</div>
        <div style="height: 1rem; font-weight: bold">微信</div>
        <div class="gfwximg" v-show="isssshow" @mouseout="mouseouttt">
          <img :src="hfliat.gfwx" />
        </div>
      </div>
      <div v-on:click="goTop">
        <img src=".././assets/image/Slice19.png" alt="回到顶部" style="margin-top: 16px" />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment/moment';
import vueSeamlessScroll from 'vue-seamless-scroll';
import LybSwiper from '@/components/lyb-swipe';
import { news, detail, columnTitle } from '@/api/index.js';
import { $fmtTime } from '@/utils/utils.js';
import "quill/dist/quill.core.css"
export default {
  name: 'Home',
  filters: {
    ellipsis(value) {
      // 正则去除html标签
      value = value.replace(/<[^>]+>/g, '');
      // 判断是否为空
      if (!value) return '';
      // 不为空将显示字数小于90个
      if (value.length > 25) {
        return value.slice(0, 25) + '...';
      }
      return value;
    },
    ellipsisssss(value) {
      // 正则去除html标签
      value = value.replace(/<[^>]+>/g, '');
      // 判断是否为空
      if (!value) return '';
      // 不为空将显示字数小于90个
      if (value.length > 30) {
        return value.slice(0, 30) + '...';
      }
      return value;
    },
    ellipsiss(value) {
      // 正则去除html标签
      value = value.replace(/<[^>]+>/g, '');
      // 判断是否为空
      if (!value) return '';
      // 不为空将显示字数小于90个
      if (value.length > 27) {
        return value.slice(0, 27) + '...';
      }
      return value;
    }
  },
  data() {
    return {
      winwidth: document.body.clientWidth,
      winheight: document.body.clientHeight,
      // 通知公告数据保
      nobList: [],
      // 协会快讯数据
      assList: [],
      // 党建动态
      jdfcList: [],
      // 党建动态置顶文章
      zdjdfcList: [],
      // 通知公告置顶文章
      znobList: [],
      isssshow: false,
      isshow: false,
      hfliat: {},
      hyfcList: [],
      optionCustomer: {
        // step: 1,
        limitMoveNum: 5, //限制开始滚动的条数
        // openTouch: false,
        waitTime: 50, //滚动的速度
        direction: 2, //方向 0 往下 1 往上 2向左 3向右
        // singleWidth: 30
        scrollW: 0 //记录悬停的位置
      },
      columnName1:'',
      columnName2:'',
      columnName3:'',
      columnName4:'',
      columnName5:'',
      columnName6:'',

      djfcfmimg:[],
      xhkxfmimg:[],
      hyfcfmimg:[],
    };
  },
  computed: {
    time() {
      return $fmtTime;
    }
  },
  mouted() {
    this.scroll();

  },
  destroyed() {
    clearInterval(this.setInter);
  },
  //声明传递过来的子组件
  components: {
    LybSwiper,
    vueSeamlessScroll
  },
  created() {
    // 置顶文章
    this.handleznoblist();
    // 通知公告
    this.handlenoblist();
    this.handleassList();
    this.handlejdfcList();
    this.handlezdjdfcList();
    this.handleheadfoot();
    // 会员风采
    this.handlehyfcfcList();

  },
  methods: {
    // transitionEnd(index) {},
    // 回到顶部
    goTop() {
      cancelAnimationFrame(this.timer);
      const self = this;
      self.timer = requestAnimationFrame(function fn() {
        const oTop = document.body.scrollTop || document.documentElement.scrollTop;
        if (oTop > 0) {
          document.body.scrollTop = document.documentElement.scrollTop = oTop - 50;
          self.timer = requestAnimationFrame(fn);
        } else {
          cancelAnimationFrame(self.timer);
        }
      });
    },
    // 获取通知公告置顶数据
    handleznoblist() {
      news({ siteId: 2, cate_id: 1, online: 1, enabled: 1, istop: 1 }).then(res => {
        this.znobList = res.data.data.records;
      });
    },
    // 获取通知公告数据
    handlenoblist() {
      news({ siteId: 2, cate_id: 1, online: 1, enabled: 1 }).then(res => {
        this.nobList = res.data.data.records;
        this.nobList.forEach(item => {
          item.createTime = moment(item.createTime).format('YYYY-MM-DD');
        });
      });
      columnTitle(1).then(res => {
        this.columnName1 = res.data.data.name
      })
    },
    // 获取协会快讯数据
    handleassList() {
      news({ siteId: 2, cate_id: 3, online: 1, enabled: 1, limit: 11 }).then(res => {
        this.assList = res.data.data.records;
        this.assList.forEach(item => {
          item.files = JSON.parse(item.files);
          item.createTime = moment(item.createTime).format('YYYY-MM-DD');
        });
        this.assList.map((item,index) => {
          if(item.files.fmimg != undefined) {//有fmimg
            if(item.files.fmimg.length == 0) {
                this.xhkxfmimg[index] = ""
            }else {
              this.xhkxfmimg.push(item.files.fmimg[0].src)
            }
          }else{
            item.files.map(items => {
              if(this.getFileType(items.src) == 'image') {
                this.xhkxfmimg.push(items.src)
              }else {
                this.xhkxfmimg[index] = ""
              }
            })
          }
        })
      });
      columnTitle(2).then(res => {
        this.columnName2 = res.data.data.name
      })
    },
    /* 根据后缀判断文件类型 */
    getFileType(fileName) {
      let suffix = ''; // 后缀获取
      let result = ''; // 获取类型结果
      if (fileName) {
        const flieArr = fileName.split('.'); // 根据.分割数组
        suffix = flieArr[flieArr.length - 1]; // 取最后一个
      }
      if (!suffix) return false; // fileName无后缀返回false
      suffix = suffix.toLocaleLowerCase(); // 将后缀所有字母改为小写方便操作
      // 匹配图片
      const imgList = ['png', 'jpg', 'jpeg', 'bmp', 'gif']; // 图片格式
      result = imgList.find(item => item === suffix);
      if (result) return 'image';
      // 匹配txt
      const txtList = ['txt'];
      result = txtList.find(item => item === suffix);
      if (result) return 'txt';
      // 匹配excel
      const excelList = ['xls', 'xlsx'];
      result = excelList.find(item => item === suffix);
      if (result) return 'excel';
      // 匹配word
      const wordList = ['doc', 'docx'];
      result = wordList.find(item => item === suffix);
      if (result) return 'word';
      // 匹配pdf
      const pdfList = ['pdf'];
      result = pdfList.find(item => item === suffix);
      if (result) return 'pdf';
      // 匹配ppt
      const pptList = ['ppt', 'pptx'];
      result = pptList.find(item => item === suffix);
      if (result) return 'ppt';
      // 匹配zip
      const zipList = ['rar', 'zip', '7z'];
      result = zipList.find(item => item === suffix);
      if (result) return 'zip';
      // 匹配视频
      const videoList = ['mp4', 'm2v', 'mkv', 'rmvb', 'wmv', 'avi', 'flv', 'mov', 'm4v'];
      result = videoList.find(item => item === suffix);
      if (result) return 'video';
      // 匹配音频
      const radioList = ['mp3', 'wav', 'wmv'];
      result = radioList.find(item => item === suffix);
      if (result) return 'radio';
      // 其他文件类型
      return 'other';
    },
    // 获取建档风采数据
    handlejdfcList() {
      news({ siteId: 2, cate_id: 4, online: 1, enabled: 1 }).then(res => {
        this.jdfcList = res.data.data.records;
        this.jdfcList.forEach(item => {
          item.files = JSON.parse(item.files);
        });
        this.jdfcList.map((item,index) => {
          if(item.files.fmimg != undefined) {//有fmimg
            if(item.files.fmimg.length == 0) {
                this.djfcfmimg[index] = ""
            }else {
              this.djfcfmimg.push(item.files.fmimg[0].src)
            }
          }else{
            item.files.map(items => {
              if(this.getFileType(items.src) == 'image') {
                this.djfcfmimg.push(items.src)
              }else {
                this.djfcfmimg[index] = ""
              }
            })
          }
        })
      });
      columnTitle(3).then(res => {
        this.columnName3 = res.data.data.name
      })
    },
    // 获取建档风采置顶数据
    handlezdjdfcList() {
      news({ siteId: 2, cate_id: 4, online: 1, enabled: 1, istop: 1 }).then(res => {
        this.zdjdfcList = res.data.data.records;
      });
      columnTitle(4).then(res => {
        this.columnName4 = res.data.data.name
      })
    },
    // 获取会员风采置顶数据
    handlehyfcfcList() {
      news({ siteId: 2, cate_id: 5, online: 1, enabled: 1, limit: 20 }).then(res => {
        this.hyfcList = res.data.data.records
        this.hyfcList.forEach(item => {
          item.files = JSON.parse(item.files)
        });
        this.hyfcList.map((item,index) => {
          if(item.files.fmimg != undefined) {//有fmimg
            if(item.files.fmimg.length == 0) {
                this.hyfcfmimg[index] = ""
            }else {
              this.hyfcfmimg.push(item.files.fmimg[0].src)
            }
          }else{
            item.files.map(items => {
              if(this.getFileType(items.src) == 'image') {
                this.hyfcfmimg.push(items.src)
              }else {
                this.hyfcfmimg[index] = ""
              }
            })
          }
        })
      })
      columnTitle(5).then(res => {
        this.columnName5 = res.data.data.name
      })
    },
    // 传递值
    // handlelitxq(id) {
    //   this.$router.push({ path: '/notann/' + id });
    // },
    handlelitxq(id) {
      let url = this.$router.resolve({ path: 'notann', query: { id: id } });
      // window.open(url.href, '_blank');
    },
    //鼠标传入事件
    mouseOver() {
      this.isssshow = true;
      this.isshow = false;
    },
    mouseoutot() {
      this.isssshow = false;
      this.isshow = false;
    },
    mouseouttt() {
      this.isssshow = false;
      this.isshow = false;
    },
    mouseOvero() {
      this.isssshow = false;
      this.isshow = true;
    },
    mouseouto() {
      this.isssshow = false;
      this.isshow = false;
    },
    mouseoutt() {
      this.isssshow = false;
      this.isshow = false;
    },
    //头部底部数据
    handleheadfoot() {
      detail(2).then(res => {
        this.hfliat = JSON.parse(res.data.data.config);
      });
    },

    // 给vue-seamless-scroll第二组添加点击事件
    handleSectionClick(e) {
      let ttarget = e.target;
      const _this = this;
      if (ttarget.tagName === 'IMG') {
        ttarget.onclick = function () {
          let id = ttarget.dataset.id;
          _this.$router.push({ path: '/notann', query: { id: id } });
        };
      }
    }
  }
};
</script>
<style lang="stylus" scoped>
.routerbox {
  display:flex;
  // border:1px solid red;
  width:100%;
  color:#333;
  align-items:center;
}
.routerbox:visited {
  color:#333;
}
a
  text-decoration: none;

.inpbox
  position relative
.inpbox >img
  position: absolute
  top:0
  left:0
  z-index:999
.inpbox > input
  padding:0 30px p !important

#home
  width: 100%
  background:rgb(243, 243, 243)
  position relative
  .widmar
    max-width:1200px
    margin: 0 auto
    background: #fff
    @media screen and (max-width:800px)
      min-width:100%
  .hddbbox
    position:fixed
    top :60%
    right: 10px
    width: 50px
    color:#fff
    z-index:99999
    @media screen and (max-width:800px)
      display:none
  .hddbbox >div
    cursor: pointer;
    // background: #84868a;
    background:#89b0c2;
    width: 60px;
    height: 60px;
    margin: 14px 0;
    text-align: center;
    line-height: 45px;
    border-radius: 3px;
    font-size: 16px;
  .hddbbox >div >span
    width: 100%

.lxwmbox
  position:relative
  .lxwmlist
    width:230px
    background: #F4F5F8
    position:absolute
    top:0
    left:-237px
    z-index:999
    padding:20px 0
    .lxwmlist >h3
      font-size:16px;
      color:#525252
.swiper-slide >a
  position: relative
  display: inline-block
  width:100%;
  height:100%;
// .swiperpt
//   width: 99% !important;
.swiper-slide >a >p
  position: absolute;
  bottom: 0px;
  left: 0;
  background: linear-gradient(to bottom, rgba(12,12,12,0.05),rgba(12,12,12,0.88));
  color:#F3F9FF;
  font-size: 16px
  padding:0 2%;
  width: 96%;
  height: 52px;
  line-height: 52px;
  @media screen and (max-width:800px) {
    font-size: 18px;
  }
.tsweractive{
   animation:10s wordsLoop linear infinite normal;
   display:inherit;
}
@keyframes wordsLoop {
    0% {
        transform: translateX(20%);
        -webkit-transform: translateX(20%);
    }
    100% {
        transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
    }
}
@-webkit-keyframes wordsLoop {
    0% {
        transform: translateX(100%);
        -webkit-transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
    }

}.lxwmul >li
  display:flex
  font-size: 12px
  height: 36px;
  line-height: 36px;
.lxwmul >li >div:first-child
  flex:4
  color: #7A7A7A
  text-align: left
  margin-left:19px
  margin-right:10px;
.lxwmul >li >div:last-child
  flex:8
  color: #525252
  margin-right:19px
.gfwxbox
  position:relative
.gfwximg
  position :absolute
  top:0
  left:-157px
  z-index:9999999
.gfwximg >img
  width:150px
  height:150px
.asscont1
  display: flex;
  margin:25px 20px 0 20px;
  justify-content: space-between;
  @media screen and (max-width:810px) {
    display:flex;
    flex-wrap: wrap;
    // margin:0;
  }
  @media screen and (max-width:450px) {
    display: block
  }
.asscont1 >a {
   @media screen and (max-width:450px) {
    display: block;
    width:50%;
    float:left;
    text-align: center;
  }
}
.assint:nth-child(6){
  padding-bottom:15px;
}
.assint
  padding-top: 20px;
  @media screen and (max-width:1015px) {
    padding:5px;
  }
  .assibox
    width: 98%;
    border-bottom: 1px solid #ddd;
    font-family:Microsoft YaHei;
    overflow: hidden
    margin:0 10px;
    @media screen and (max-width:800px) {
      padding-top:15px;
    }
    .asspan
      height: 37px;
      background: #2ea8e1;
      display: block;
      width: 140px;
      line-height: 37px;
      text-align: center;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      float: left;
  .asscont
    display:flex;
    padding:20px 10px 0 10px
    @media screen and (max-width:1200px),( min-width:1200px)
      padding-top: 15px;

    @media screen and (max-width:800px)
      // padding:0;
      display:flex;
      flex-flow: column;
  .acl
    width:495px;
    height:278px;
    padding-right: 30px;
    border-right: 1px solid #E6E6E6;
    flex:4
    @media screen and (max-width:1065px)
      display: none;
      padding-right:0;
      border-right: 0;
      .acr
        margin-top: 5hv;
        width: 100%;
  .acl img
    width: 100%;
    height:100%;
    object-fit: initial;
  .acr
    width:495px;
    // height:278px;
    flex:5
    // margin: 0px 7px 0 30px;
    @media screen and (max-width:1065px)
      // margin: 5px 0 0 15px;
      width: 100%;
  .acr h3
    font-size: 20px;
    color: #4A4A4A;
    font-family: Microsoft YaHei;
    line-height: 1em
  .acr > h3
    margin-bottom:15px;
    @media screen and (max-width:1065px) {
      text-align: center;
    }
  .acr>.assContent
    height:222px
    font-size: 16px;
    color: #525252;
    line-height: 1.7rem;
    font-weight: 400;
    font-family: Microsoft YaHei;
    margin-top:3px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 8;
    @media screen and (max-width:800px)
      text-indent:2rem;
      margin-right:15px;
  // .acr>p:after
  //   content:'...';
  //   bottom:0;
  //   right:0;
  .assintimg
    @media screen and (min-width:1065px) {
      display:none;
    }
  .assimg
    // margin: 25px 58px 0 20px;
    flex: 1
    text-align: center;
    @media screen and (max-width:800px) {
      width:33%;
      float:left;
      margin-right:0px !important;
    }
    @media screen and (max-width:500px) {
      width:50%;
      float:left;
      margin-right:0px;
    }
  .assimg:nth-child(6)
    margin-right:16px
  .assimg >a >img
    max-width:130px
    max-height: 91px
    @media screen and (max-width:775px) {
      width:100%;
    }
.notice
  display: flex;
  padding-top:10px
  overflow:hidden;
  @media screen and (max-width:800px)
    display: block
    margin:0
.noboxle
  max-width:573px
  max-height: 384px
  flex: 1
  margin-left:10px
  @media screen and (max-width:800px)
    min-width: 100%
    margin-left: 0px
    // background: red
.noboxre
  // flex: 1;
  max-width:587px;
  margin-left: 30px
  @media screen and (max-width:1013px)
    max-width:100%;
    margin:0
  .nobtop
    color: #346CC6
    border-bottom:1px solid #EBEAEA
    height: 37px
    line-height: 59px
    margin-right:10px;
    @media screen and (max-width:800px) {
      margin:15px;
    }
  .nobtoptit
    height: 37px;
    background: #2ea8e1;
    display: block;
    width: 140px;
    line-height: 37px;
    text-align: center;
    color: #FFF;
    font-size: 16px;
    font-weight: bold;
    float: left
  .nobtoprou >a
    float: right
    color:#B6B6B6
    font-size: 14px
    margin-top:-7px
  .nobcont{
    @media screen and (max-width:1015px) {
      width:94%;
      margin:25px;
    }
    @media screen and (max-width:600px) {
      width:90%;
    }
     @media screen and (max-width:512px) {
      width:84%;
    }
  }
  .nobcont > ul
    margin-left:15px;
    displat:flex;
    flex-direction: column;
    @media screen and (max-width:1010px) {
      margin-left:0
    }
  .nobcont > ul >li
    margin: 22px 0
    font-size: 16px
    text-decoration: none
    color:#525252
    cursor: pointer;
    display:flex
    position:relative
    padding-left:10px;
    @media screen and (max-width:800px) {
      font-size: 16px;
    }
  .nobcont > ul >li:before{
    content: '';
    display: inline-block;
    width: 4px;
    height: 4px;
    left: 0px;
    top: 10px;
    position: absolute;
    border-radius: 50%;
    background: #2ea8e1;
  }
  // .nobcont > ul >li:nth-child(1){
  //   color:#2ea8e1;
  // }
  // .nobcont > ul >li:nth-child(2){
  //   color:#2ea8e1;
  // }
  .topnews {
    color:#2ea8e1!important;
  }
  .nobcont > ul >li:nth-child(8){
    margin-bottom: 0px;
  }
  .nobcont > ul >li:hover
    color:#2ea8e1
  .npbtime
    float: right
    color:#A9A9A9
    font-size: 12px
    margin-right:15px
    text-align: right;
    @media screen and (max-width:800px) {
      margin-right:0px;
      margin-top:3px;
    }
.asspantwo >a
  float: right;
  color: #b6b6b6;
  font-size: 14px;
  margin-top: 11px;
.aclul
  color:#525252
  font-size:16px
  // margin-left: 35px
  @media screen and (max-width:90px)
    // margin:0
.aclul >li
  list-style :disc
  margin:  18px 0
  display: flex
  position:relative
  padding-left:10px
.aclul >li:before
  content: ''
  display: inline-block
  width: 4px
  height: 4px
  left: 0px
  top: 10px
  position: absolute
  border-radius: 50%
  background: #2ea8e1
.aclul >li:nth-child(7)
 margin-bottom: 0px;
.aclul >li
  text-decoration:none
  color:#525252
.aclul >li:hover
  color:#2ea8e1
  cursor: pointer;
.acltime
  float: right
  color: #A9A9A9
  font-size: 12px;
  width:110px;
  text-align: right;
.index-default
  @media screen {

  }
.acrul
  margin-right:15px
  @media screen and (max-width:800px)
    margin-left: 15px
    margin-right:0px
    text-overflow :ellipsis
.acrul >li
  margin-top:5px
  position relative
.acrul >li:first-child{
  margin-top:0px
}
.acrul >li
  height: 60px
  display: flex
  color: #525252
  font-size: 16px
  line-height: 1.3rem
  border-bottom: 1px solid #CACACA
  text-decoration:none
  color:#525252
  align-items:center
  @media screen and (max-width:900px)
    border:0
    margin:11px 0
    height:24px;
    font-size: 16px
.acrul >li:last-child >a
  border: 0
.acrul >li:nth-child(4)
  border: 0
.acrdone
  width:92px;
  @media screen and (max-width:800px)
    display: none;
.acrdone>img
  width 100%
.acrdtwo
  flex: 8
  height:45px
  word-break:break-all
  display: -webkit-box
  // -webkit-box-orient:vertical
  overflow:hidden
  -webkit-line-clamp: 2;
  margin-left: 5px;
  margin-top:6px;
  // -webkit-box-orient:none
  @media screen and (max-width:800px)
    word-break:break-all;
    white-space:nowrap;
    text-overflow:ellipsis;
    overflow:hidden;
    height:20px
    display:block;
    padding-right: 4px;
.acrdtwo:hover
  color:#2ea8e1
  cursor: pointer;
.acrtime
  // position: absolute
  // right:0
  // bottom:0px
  color: #A9A9A9
  font-size:12px
  @media screen and (max-width:800px){
    position: relative;
  }
.asscont2
  display: flex
  margin:15px 10px 0 10px;
  @media screen and (max-width:900px)
    display: block

.acl2
  // padding-left: 15px;
  max-width: 600px
  @media screen and (max-width:900px)
    max-width:100%;
    padding-right: 30px;
    margin-top:22px;
    padding: 0
    border: 0
  // flex: 1;
.acl2>ul
  margin-top:-15px
  margin-left:20px;
.acr2
  flex: 1
  // margin-left:15px
  // padding-right:15px;
  border-right: 1px solid #e6e6e6;
  @media screen and (max-width:800px)
    margin:15px 0 0 0
    border:0;

.asrolpp
  position: absolute;
  bottom: 0px;
  left: 0;
  background: #b3b3b3;
  color:#F3F9FF;
  font-size: 24px
  opacity: 0.67;
  padding-left:8px;
  width: 99%;
  height: 52px;
  line-height: 52px;
.jdimg
  height:62px
.asscol
  flex: 1
  @media screen and (max-width:1065px)
    margin-left:15px;
.asscol >ul >li:nth-child(5){
  border-bottom:none
}
.assrol
  position: relative
  flex: 1
  margin-left:15px
  max-width: 580px
  height: 327px
  @media screen and (max-width:1065px)
    height: 423px
    display:none;
  @media screen and (max-width:800px)
    margin-left:0
    position:relative
  .assrolsttit
    position: absolute;
    top: 368px;
    left: 3px;
    background: #696868;
    min-width:99%;

    height: 52px;
    line-height: 52px;
    color: #F3F9FF;
    font-size: 24px;
    opacity: 0.5;
.jdlist
  border-bottom: 1px solid #CACACA
  margin:3px 0
.jdlist:list-child{
    border-bottom: 0;
}
.jdlist:first-child{
  margin-top:0;
}
.jdlist:list-child{
  margin-bottom:0;
}
.jdlist:nth-child(6)
  border: 0
.jdlist
  display:flex
  text-decoration:none
  color:#525252
.jdtit
  font-size: 16px
  height:44px
  margin:10px 0 0 5px
  line-height: 1.3rem
  width: 100%;
.jdtit:hover
  color:#2ea8e1
  cursor: pointer;
// .jdtit>span
//   float:right
//   color: #A9A9A9;
//   font-size: 14px
//   @media screen and (max-width:1160px) {
//     display:none;
//   }
.assintwzbox{
  width:2400px;
  overflow:hidden;
}
.assintwz
  max-width:240px
  max-height:190px
  position: relative
  cursor: pointer
  float:left
.assintwz >a>img
  max-width:223px
  height:145px
  margin-right:17px
.assintwz >a>p
  position: absolute
  top: 100px;
  left:0px;
  width:85%
  padding:0 4%
  font-size: 14px
  max-width:223px
  height:45px;
  line-height: 1.4rem;
  // background: rgba(105,104,104,0.67)
  background: linear-gradient(to bottom, rgba(0,0,0,0.1),rgba(12,12,12,0.88));
  color: #f3f9ff
  font-size: 13px
.seamless-warp
  width:6000px;
  line-height:145px;
  height: 145px;
  overflow: hidden;
.cont3box
  min-width:100%;
  max-width:100%
  display:flex
  @media screen and (max-width:800px)
    // display:block
    // overflow: hidden
    // max-height:250px
// .cont3anima
//   animation: 30s wordsLoop linear infinite normal;
//  @keyframes wordsLoop {
//     0% {
//         transform: translateX(0px);
//         -webkit-transform: translateX(0px);
//     }
//     100% {
//         transform: translateX(-100%);
//         -webkit-transform: translateX(-100%);
//     }
// }

// @-webkit-keyframes wordsLoop {
//     0% {
//         transform: translateX(200px);
//         -webkit-transform: translateX(200px);
//     }
//     100% {
//         transform: translateX(-100%);
//         -webkit-transform: translateX(-100%);
//     }
// }
.cont3box >img
  flex:1
  margin:8.5px
  width: 19%
  @media screen and (max-width:800px)
    width:100%
    margin: 0

.sspanafbox
  background: #2ea8e1;
  width: 2px;
  margin-left: 3px;
  height: 37px;
  float: left;

.asscont
  padding:0px
@media screen and (max-width: 1104px)
	.nobcont >ul >li >span
		display:none
@media (max-width: 1065px)
  // .aclul >li >span
  //   display:none
  .acl2
    padding-right:0px
  .acl > img
    width:100%;
    height:100%
  .acr2
    margin-left: 0px
  .asscont
    display:block !important
  .jdtit > span
    display:block
  .assrol
    max-width:1165px
    margin:0
@media screen and (max-width: 1013px)
  .notice
    display:block
  .noboxle
    max-width:100%
    height:70vw
    margin-left: 0px;
  .noboxre
    max-width:100%
    margin-left: 0px;
  .nobcont > ul >li >span
    display:block
  @media  screen and (max-width:800px)
    .aclul
      margin-left:15px;
      margin-top:-5px !important;
    .aclul >li
      list-style: none;
      margin: 14px 0
    .aclul >li >span
        display:block
      // .assibox
      // display:none

@media screen and (max-width:1160px)
  // .jdtit >span
  //   display:none;
@media screen and (max-width:1066px)
  .asscont
    display:block !important;


.jdlist:nth-child(5){
  border-bottom:0;
}

.ql-editor .ql-align-center {
  text-align:center;
}

</style>
