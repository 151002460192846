import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
import home from '../views/home.vue';
import assint from '../views/assint.vue';
import asoser from '../views/asoser.vue';
import assmem from '../views/assmem.vue';
import concen from '../views/concen.vue';
import softoa from '../views/softoa.vue';
import search from '../views/search.vue';
import notann from '../views/notann.vue';
import assnew from '../views/assnew.vue';
import famous from '../views/famous.vue';
import character from '../views/character.vue';
import products from '../views/products.vue';
import associ from '../views/associ.vue';
import details from '../views/details.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: home,
    meta: {
      title: '成都市软件行业协会'
    }
  },
  {
    path: '/home',
    name: 'thome',
    component: home,
    meta: {
      title: '成都市软件行业协会'
    }
  },
  {
    path: '/assint',
    name: 'assint',
    component: assint,
    meta: {
      title: ' 协会简介'
      // path: '/assint'
    }
  },
  {
    path: '/associ',
    name: 'associ',
    component: associ,
    meta: {
      title: '协会章程'
    }
  },
  {
    path: '/asoser',
    name: 'asoser',
    component: asoser,
    meta: {
      title: '协会服务'
    }
  },
  {
    path: '/softoa',
    name: 'softoa',
    component: softoa,
    meta: {
      title: '通知公告'
    }
  },
  {
    path: '/assmem',
    name: 'tassmem',
    component: assmem,
    meta: {
      title: '会员风采'
    }
  },
  {
    path: '/concen',
    name: 'concen',
    component: concen,
    meta: {
      title: '党建动态'
    }
  },
  {
    path: '/assmem',
    name: 'assmem',
    component: () => import('../views/famous.vue'),
    // redirect:'/'
    meta: {
      title: '会员风采'
    }
  },
  {
    path: '/famous',
    name: 'famous',
    component: famous,
    meta: {
      title: '明星企业'
    }
  },
  {
    path: '/character',
    name: 'character',
    component: character,
    meta: {
      title: '人物风采'
    }
  },
  {
    path: '/products',
    name: 'products',
    component: products,
    meta: {
      title: '优质产品'
    }
  },
  {
    path: '/assnew',
    name: 'assnew',
    component: assnew,
    meta: {
      title: '协会快讯'
    }
  },
  {
    path: '/details',
    name: 'details',
    component: details,
    meta: {
      title: '成都市软件行业协会'
    }
  },
  {
    path: '/search',
    name: 'search',
    component: search,
    meta: {
      title: '搜索结果'
    }
  },
  {
    path: '/notann',
    name: 'notann',
    component: notann,
    meta: {
      title: '成都市软件行业协会'
    }
  }
];

const router = new VueRouter({
  // mode:'history',
  routes
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};
router.afterEach(() => {
  window.scrollTo({ behavior: 'smooth', top: 0 });
});
export default router;
