<template>
  <div id="asstit">
    <div class="asslto">
      <span>{{ msgtit }}</span>
    </div>
  </div>
</template>

<script>
// 导出组件
export default {
  name: 'asstit',
  props: ['msgtit']
};
</script>
<style lang="stylus" scoped>
.asslto
  width:100%
  border-bottom: 1px solid #dadada
  .asslto
  >span
    font-size:20px
    padding:0 20px 5px 20px
    color:#346CC6
    border-bottom:2px solid #346CC6
    margin-top:30px
    display: inline-block;
</style>
