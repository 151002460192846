<template>
  <div class="famous" style="background: #f3f3f3">
    <div class="asmbox">
      <div class="assle">
        <div class="assltt">
          <ul>
            <li>
              <router-link to="/softoa" class="navas">
                <div class="nav-box">通知公告</div>
              </router-link>
            </li>
            <li>
              <router-link to="/asoser" class="navas">
                <div class="nav-box">协会服务</div>
              </router-link>
            </li>
            <li>
              <router-link to="/assnew" class="navas">
                <div class="nav-box">协会快讯</div>
              </router-link>
            </li>
            <li>
              <router-link to="/concen" class="navas">
                <div class="nav-box">党建动态</div>
              </router-link>
            </li>
          </ul>
          <el-collapse accordion v-model="activelist">
            <el-collapse-item title="会员风采" :name="6">
              <div class="sfqitem active">
                <router-link to="/famous" class="navas" style="color: #fff"> 明星企业 </router-link>
              </div>
              <div class="sfqitem">
                <router-link to="/character" class="navas"> 人物风采 </router-link>
              </div>
              <div class="sfqitem">
                <router-link to="/products" class="navas"> 优质产品 </router-link>
              </div>
            </el-collapse-item>
          </el-collapse>
          <el-collapse accordion>
            <el-collapse-item title="关于协会" name="7">
              <div class="sfqitem">
                <router-link to="/assint" class="navas"> 协会简介 </router-link>
              </div>
              <div class="sfqitem">
                <router-link to="/associ" class="navas"> 协会章程 </router-link>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
      <div class="assre">
        <div class="assrebox">
          <div class="assreboxo">当前位置&nbsp;>&nbsp;<span>会员风采</span></div>

          <div class="assmembox">
            <div class="nobtop">
              <span class="nobtoptit">明星企业</span><span class="sspanafbox"></span
              ><span class="nobtoprou">
                <router-link to="/softoa"> 更多>></router-link>
              </span>
            </div>
            <div class="conulbox">
              <ul>
                <li v-for="item in newList" v-bind:key="item.id" @click="handlelitxq(item.id)">
                  <div style="display: flex">
                    <div class="litit one-line" v-html="item.title"></div>
                    <div class="wztime">{{ item.createTime }}</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="assmembox">
            <div class="nobtop">
              <span class="nobtoptit">人物风采</span><span class="sspanafbox"></span
              ><span class="nobtoprou">
                <router-link to="/character"> 更多>></router-link>
              </span>
            </div>
            <div class="conulbox">
              <ul>
                <li v-for="item in newListwo" v-bind:key="item.id" @click="handlelitxq(item.id)">
                  <div style="display: flex">
                    <div class="litit one-line" v-html="item.title"></div>
                    <div class="wztime">{{ item.createTime }}</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="assmembox">
            <div class="nobtop">
              <span class="nobtoptit">优质产品</span><span class="sspanafbox"></span
              ><span class="nobtoprou">
                <router-link to="/products"> 更多>></router-link>
              </span>
            </div>
            <div class="conulbox">
              <ul>
                <li v-for="item in newListhree" v-bind:key="item.id" @click="handlelitxq(item.id)">
                  <div style="display: flex">
                    <div class="litit one-line" v-html="item.title"></div>
                    <div class="wztime">{{ item.createTime }}</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment/moment';
import { news } from '@/api/index.js';
import { $fmtTime } from '@/utils/utils.js';
export default {
  name: 'famous',
  filters: {
    ellipsis(value) {
      // 正则去除html标签
      if (value.match(/[\u4e00-\u9fa5]/g)) {
        value = value.match(/[\u4e00-\u9fa5]/g).join('');
      } else {
        return;
      }
      // 不为空将显示字数小于90个
      if (value.length > 100) {
        return value.slice(0, 100) + '...';
      }
      return value;
    },
    ellipsisss(value) {
      // 正则去除html标签
      value = value.replace(/<[^>]+>/g, '');
      // 判断是否为空
      if (!value) return '';
      // 不为空将显示字数小于90个
      if (value.length > 42) {
        return value.slice(0, 42) + '...';
      }
      return value;
    }
  },
  data() {
    return {
      form: {
        siteid: 2,
        cateid: 5000,
        online: 1,
        enabled: 1,
        page: 1,
        limit: 5
      },
      formtwo: {
        siteid: 2,
        cateid: 5001,
        online: 1,
        enabled: 1,
        page: 1,
        limit: 5
      },
      formthree: {
        siteid: 2,
        cateid: 5002,
        online: 1,
        enabled: 1,
        page: 1,
        limit: 5
      },
      newList: [],
      newListwo: [],
      newListhree: [],
      total: 0, //总页数
      urlname: this.$route.path,
      title: document.title,
      activelist: 6
    };
  },
  computed: {
    time() {
      return $fmtTime;
    }
  },

  //声明传递过来的子组件
  components: {},
  created() {
    this.getList();
    this.getListtwo();
    this.getListthree();
  },
  methods: {
    getList() {
      news(this.form).then(res => {
        this.newList = res.data.data.records;
        this.newList.forEach(item => {
          item.createTime = moment(item.createTime).format('YYYY-MM-DD');
        });
      });
    },
    getListtwo() {
      news(this.formtwo).then(res => {
        this.newListwo = res.data.data.records;
        this.newListwo.forEach(item => {
          item.createTime = moment(item.createTime).format('YYYY-MM-DD');
        });
      });
    },
    getListthree() {
      news(this.formthree).then(res => {
        this.newListhree = res.data.data.records;
        this.newListhree.forEach(item => {
          item.createTime = moment(item.createTime).format('YYYY-MM-DD');
        });
      });
    },
    handlelitxq(id) {
      this.$router.push({ path: 'notann', query: { id: id, url: this.urlname, title: this.title } });
    },
    handleSizeChange(val) {
      this.form.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.getList();
    }
  }
};
</script>
<style lang="stylus" scoped>
.assmembox{
  border-top:1px solid #414141;
  margin-top: 15px;
  padding: 30px 0 15px 0;
}
.assmembox:nth-child(4){
  border-bottom: 1px solid #414141;
}
.nobtop{
  color: #346cc6;
  border-bottom: 1px solid #ebeaea;
  height: 37px;
  line-height: 59px;
  margin-right: 10px;
}
.nobtoptit{
  height: 37px;
  background: #2ea8e1;
  display: block;
  width: 140px;
  line-height: 37px;
  text-align: center;
  color: #fff;
  font-size: 20px;
  float: left;
}
.sspanafbox{
  background: #2ea8e1;
  width: 2px;
  margin-left: 3px;
  height: 37px;
  float: left;
}
.nobtoprou >a{
  float: right;
  color: #b6b6b6;
  font-size: 14px;
  margin-top: -7px;
}
.conulbox >ul >li{
  border:0;
  margin-top:20px;
}
.litit{
  color: #5a5a5a !important;
}
.conulbox{
  margin-bottom: 0px;
}
.assmembox:nth-child(4){
  margin-bottom:30px;
}
</style>
