import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// import '@/swiper/swiper-bundle.min.css';
// import '@/swiper/swiper-bundle.min.js';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/public.css';
import axios from 'axios';
import VueAxios from 'vue-axios';
import '@/assets/style/default.css';
import QRCode from 'qrcode';
import moment from 'moment/moment';
import './assets/share/iconfont/iconfont.css'

import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme

Vue.use(VueQuillEditor, /* { default global options } */)



Vue.use(VueAxios, axios);
// Vue.use(Button);
// Vue.use(Select);
Vue.use(ElementUI);
Vue.use(QRCode);
Vue.use(moment);

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
