<template>
  <div class="search" style="background: #f3f3f3">
    <div class="searchtit">
      <div class="seablzj">
        搜索到 “{{ inplist.title | ellipsisss }}” 共 <span>{{ inplist.total }}</span
        >条结果
      </div>
    </div>

    <div class="searchbox">
      <div class="seabr">
        <div style="margin: 0 23px">
          <asstit msgtit="热点内容"></asstit>
          <ul class="seabrul">
            <li
              v-for="item1 in rdlist.slice(0, 5)"
              v-bind:key="item1.id"
              @click="handlelitxq(item1.id)"
              v-html="item1.title"
            >
              {{ item1.title }}
            </li>
          </ul>
        </div>
      </div>
      <div class="seabl">
        <div class="seabluls">
          <ul>
            <li class="seazxlis" v-for="item in serlist" v-bind:key="item.id" @click="handlelitxq(item.id)">
              <!-- <div class="sealisl">
                  <span>本地资讯</span>
                </div> -->
              <div class="sealisr">
                <div style="display: flex">
                  <!-- <div :class="item.istop ? 'litit1 one-line' : 'litit one-line'" v-html="item.title"></div> -->

                  <div class="litit one-line">
                    <img src="../assets/image/wzzd.png" style="width: 12px" alt="" v-if="item.istop" />
                    <span>{{ item.title }}</span>
                  </div>
                  <!-- <div class="zdspan" v-if="item.istop">置顶</div> -->
                  <div class="wztime">{{ item.createTime }}</div>
                </div>
                <div class="licont two-line" v-if="item.summary">{{ item.summary }}</div>
                <div class="licont two-line" v-else>{{ item.content | ellipsis }}</div>
              </div>
            </li>
          </ul>
        </div>
        <div class="seablym">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :hide-on-single-page="value"
            :current-page.sync="inplist.page"
            :page-size="15"
            layout="prev, pager, next, jumper"
            :total="inplist.total"
          >
          </el-pagination>
        </div>
        <div class="seabxxfy">
          <el-pagination
            small
            layout="prev, pager, next"
            :total="inplist.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="inplist.page"
            :page-size="15"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment/moment';
import asstit from '../components/index/asstit.vue';
import { news } from '@/api/index.js';
import { $fmtTime } from '@/utils/utils.js';
export default {
  name: 'search',
  filters: {
    ellipsis(value) {
      // 正则去除html标签
      if (value.match(/[\u4e00-\u9fa5]/g)) {
        value = value.match(/[\u4e00-\u9fa5]/g).join('');
      } else {
        return;
      }
      // 不为空将显示字数小于90个
      if (value.length > 100) {
        return value.slice(0, 100) + '...';
      }
      return value;
    },
    ellipsiss(value) {
      // 正则去除html标签
      value = value.replace(/<[^>]+>/g, '');
      // 判断是否为空
      if (!value) return '';
      // 不为空将显示字数小于80个
      if (value.length > 13) {
        return value.slice(0, 13) + '...';
      }
      return value;
    },
    ellipsisss(value) {
      // 正则去除html标签
      value = value.replace(/<[^>]+>/g, '');
      // 判断是否为空
      if (!value) return '';
      // 不为空将显示字数小于90个
      if (value.length > 30) {
        return value.slice(0, 30) + '...';
      }
      return value;
    }
  },
  data() {
    return {
      inplist: {
        siteId: 2,
        online: 1,
        enabled: 1,
        english: 0,
        title: this.$route.query.title,
        page: 1,
        limit: 15,
        total: 0
      },
      serlist: [],
      rdlist: [],
      value: false
    };
  },
  components: {
    asstit
  },
  created() {
    this.getleserlist();
    this.getlerdlist();
  },
  methods: {
    // 获取搜索数据
    getleserlist() {
      news(this.inplist).then(res => {
        console.log(res);
        this.serlist = res.data.data.records;
        this.inplist.total = res.data.data.total;
        if (this.inplist.total < 10) {
          this.value = true;
        } else {
          this.value = false;
        }
        this.serlist.forEach(item => {
          item.createTime = moment(item.createTime).format('YYYY-MM-DD');
        });
      });
    },
    // handlelitxq(id) {
    //   this.$router.push({ path: '/notann/' + id });
    // },
    handlelitxq(id) {
      this.$router.push({ path: 'notann', query: { id: id } });
    },
    handleSizeChange(val) {
      this.inplist.limit = val;
      this.getleserlist();
    },
    handleCurrentChange(val) {
      this.inplist.page = val;
      this.getleserlist();
    },
    // 获取右侧热点数据
    getlerdlist() {
      news({ siteid: 2, cateid: 1, online: 1, enabled: 1, page: 1, limit: 10 }).then(res => {
        this.rdlist = res.data.data.records;
        // console.log(this.rdlist);
      });
    }
  },
  computed: {
    time() {
      return $fmtTime;
    }
  }
};
</script>
<style lang="stylus" scoped>
.seabluls{
  margin-top:36px;
}
.searchbox {
  max-width: 1200px;
  margin: 0 auto;
  background: #fff;
  display: flex;
  @media screen and (max-width: 800px) {
    display: block;
  }
}
.seabl {
  /* flex: 5.5; */
  // width: 76%;
  width:75%;
  color: #525252;
  font-family: PingFang SC-Light;
  @media screen and (max-width:800px) {
    width:100%
  }
}
.seabr {
  // max-width: 258px;
  // flex:1
  width:25%;
  /* flex: 1.5; */
  background: #fff;
  @media screen and (max-width:800px){
    display: none;
  }
}
.searchtit{
  max-width:1200px;
  margin:0 auto;
  background: #fff
  @media screen and (max-width:800px){
    display: none;
  }
}
.seablzj {
  height:50px;
  line-height: 50px;
  color: #525252;
  margin: 0 20px;
  border-bottom: 2px dashed #c1c1c1;
  // margin: 15px 0 15px 0;
  @media screen and (max-width:800px) {
    margin:0 15px;
  }
}
.seazxlis {
  border-bottom: 2px dashed #c1c1c1;
  margin: 15px 15px 0px 15px;
  padding-bottom: 10px;
  cursor: pointer
}
.seazxlis > a {
  text-decoration: none;
  display: flex;
}
.sealisl {
  flex: 2;
}
.sealisr {
  flex: 17;
}
.sealisl > span {
  color: #346cc6;
  background: #caeeff;
  padding: 2px 5px 2px 5px;
}
.sealtdm {
  color: #2ea8e1;
  font-weight: 600;
}
.sealtsj {
  float: right;
  color: #8d8d8d;
  font-size: 13px;
}
.sealsrtit {
  color: #5a5a5a;
}
.sealtct {
  color: #8d8d8d;
  margin: 10px 0 15px 0;
}
.seablym {
  margin: 50px 80px 50px 100px;
  color: #525252;
}
.seablym > span {
  border: 1px solid #c4c4c4;
  padding: 5px 13px;
  font-size: 13px;
}
.clkcor {
  color: #fff;
  background: #4088ff;
  border: 0;
}
.fistsp {
  color: #c4c4c4;
  margin-left: 13px;
}
.clkinp {
  margin: 0 5px;
}
.clkinp > input {
  width: 22px;
  height: 20px;
  border: 0;
  outline: none;
}
.seabrul {
  color: #7a7a7a;
  font-size: 15px;
  font-family: Microsoft YaHei;
  margin-left:14px;
}
.seabrul > li {
  margin: 8px 0;
  // list-style-type disc
  cursor: pointer
  list-style:decimal
}
.seabrul > li:hover{
  color: #2ea8e1
}
.seabrul > li > a {
  text-decoration: none;
  color: #7a7a7a;
}
.assreboxo > span > a {
  color: #346cc6;
  text-decoration: none;
}
</style>
