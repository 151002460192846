<template>
  <div class="notann" style="background: #f3f3f3">
    <div class="assreboxo">
      <div class="assret">
        <!-- 当前位置：<span v-if="mbxshow"><router-link to="/famous">会员风采 &nbsp;>&nbsp;</router-link> </span>
        <span>{{ title }} </span> -->
      </div>
    </div>
    <div class="notbox">
      <div class="antboxle">
        <div class="antcont">
          <div class="notcttit">
            <h2>{{ connt.title }}</h2>
            <div>
              <h4>
                <span v-if="connt.createTime">
                  日期: <span class="tnottime">{{ connt.createTime }}</span>
                </span>
                <span v-if="connt.source"
                  >来源: <span class="tnottime">{{ connt.source }}</span></span
                >
                <span v-if="connt.organName"
                  >作者: <span class="tnottime">{{ connt.organName }}</span></span
                >
                <span v-if="connt.pv"
                  >浏览: <span class="tnottime">{{ connt.pv }}次</span>
                </span>

                <!-- v-popover:wechatShare  -->
                <span class="dsne" style="color: #346cc6" @click="fzlj">复制文章链接</span>
              </h4>
              <h4>
                <img src="@/assets/image/qq.png" style="width: 26px; height: 26px" alt="" @click="shareClick('qq')" />
                <img
                  @click="shareClick('qqZone')"
                  style="width: 26px; height: 26px"
                  src=".././assets/image/qqz.png"
                  alt=""
                />
                <img
                  @click="shareClick('weibo')"
                  style="width: 26px; height: 26px"
                  src=".././assets/image/wb.png"
                  alt=""
                />
              </h4>
            </div>
          </div>
          <div class="notwz ql-editor" v-html="connt.content" ref="connnnnnt"></div>
          <!-- <div class="files" v-show="connt.files">
              <div v-for="(item,index) in connt.files" :key="index"><a :href="item.src">{{item.fileName}}</a></div>
          </div> -->
          <div class="files" v-show="connt.files || connt.files.fujian.length">
              <div v-if="connt.files.fujian">
                <div v-for="(item,index) in connt.files.fujian"  :key="index"><a :href="item.src">{{item.fileName}}</a></div>
              </div>
              <div v-else>
                <div v-for="(item,index) in connt.files" :key="index"><a :href="item.src">{{item.fileName}}</a></div>
              </div>
          </div>
        </div>
      </div>
      <div class="antboxre">
        <div style="margin: 0 8px">
          <div class="asslto"><span>相关资讯</span></div>
          <ul class="seabrul">
            <li v-for="item in topList.slice(0, 5)" v-bind:key="item.id" @click="handlelitxq(item.id)">
              {{ item.title }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment/moment';
import QRCode from 'qrcode';
import { $fmtTime } from '@/utils/utils.js';
import { news } from '@/api/index.js';
import { newss } from '@/api/index.js';
import "quill/dist/quill.core.css"

export default {
  name: 'notann',
  filters: {
    ellipsis(value) {
      // 正则去除html标签
      value = value.replace(/<[^>]+>/g, '');
      // 判断是否为空
      if (!value) return '';
      // 不为空将显示字数小于90个
      if (value.length > 16) {
        return value.slice(0, 16) + '...';
      }
      return value;
    }
  },
  data() {
    return {
      connt: [],
      siteId: 2,
      form: {
        siteId: 2,
        cateId: 3,
        istop: 0,
        id: ''
      },
      topList: [],
      imgUrl: '',
      windowUrl: window.location.href,
      url: this.$route.query.url,
      // title: this.$route.query.title
      title: '',
      mbxshow: false
    };
  },
  computed: {
    time() {
      return $fmtTime;
    }
  },
  //声明传递过来的子组件
  components: {},
  created() {
    this.id = this.$route.query.id;
    this.creafun();
    this.getList();
  },
  methods: {
    roteindex() {
      this.$router.push({ path: '/home/' });
    },
    creafun() {
      newss(this.id).then(res => {
        this.connt = res.data.data;
        this.title = this.connt.siteCateName[2];
        this.connt.createTime = moment(this.connt.createTime).format('YYYY-MM-DD');
        //遍历图片
        this.connt.files = JSON.parse(this.connt.files);
        if(this.connt.files.length == 0) {
          this.video_show = false
          this.ZBlink = ''
        }else {
          this.video_show = true
        }
        if(this.connt.files.fujian == undefined) {
          // this.connt.files = this.connt.files
          console.log(111);
        }else {
          this.connt.files.fujian.map(item => {
            if(["png","jpg","jpeg"].indexOf(item.fileName.slice(-3)) != -1) {
              item.fileName = "图片" + item.fileName.slice(0,-4)
            }
          })
        }
        if (this.connt.siteCateName[2] === '明星企业') {
          this.mbxshow = true;
        }
        if (this.connt.siteCateName[2] === '人物风采') {
          this.mbxshow = true;
        }
        if (this.connt.siteCateName[2] === '优质产品') {
          this.mbxshow = true;
        }
      });

      // console.log(this.$route.params.id);
    },
    getList() {
      news(this.form).then(res => {
        this.topList = res.data.data.records;
      });
    },
    handlelitxq(id) {
      this.$router.push({ path: 'notann', query: { id: id } });
      location.reload();
    },
    // 分享qq、qq空间、新浪微博
    shareClick: function (type) {
      //
      if (type !== 'wechat') {
        let summary = '';
        let info = {
          url: window.location.href,
          summary
        };
        info.title = 'biaoti';
        info.desc = 'jjjj';
        this.jumpUrl(type, info);
      }
    },

    jumpUrl(type, info) {
      let jumpurl = window.location.href;
      let urlList = {
        qq:
          'http://connect.qq.com/widget/shareqq/index.html?url=' +
          encodeURIComponent(jumpurl) +
          '&desc=' +
          this.connt.title +
          '：' +
          this.connt.summary +
          encodeURIComponent(jumpurl) +
          '&title=' +
          this.connt.title +
          '&summary=' +
          this.connt.summary,
        weibo:
          'http://service.weibo.com/share/share.php?url=' +
          encodeURIComponent(jumpurl) +
          '&desc=' +
          this.connt.title +
          '：' +
          this.connt.summary +
          encodeURIComponent(jumpurl) +
          '&title=' +
          this.connt.title +
          '&summary=' +
          this.connt.summary,
        qqZone:
          'http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=' +
          encodeURIComponent(jumpurl) +
          '&desc=' +
          this.connt.title +
          '：' +
          this.connt.summary +
          encodeURIComponent(jumpurl) +
          '&title=' +
          this.connt.title +
          '&summary=' +
          this.connt.summary
      };
      if (urlList[type] && urlList[type].length) {
        // 替换url
        const shareUrl = urlList[type].replace(/{{(\w+)}}/g, (match, p1) => {
          return encodeURIComponent(info[p1] || '');
        });
        // 获取窗口的宽高
        const screenInfo = window.screen;
        const newWindowArea = {
          height: (screenInfo.height / 3) * 2,
          width: (screenInfo.width / 3) * 2
        };
        // 打开窗口
        window.open(shareUrl, 'newwindow', `height=${newWindowArea.height}, width=${newWindowArea.width}`);
      } else {
        console.warn('url不存在');
      }
    },
    fzlj() {
      let domUrl = document.createElement('input');
      domUrl.value = this.windowUrl;
      domUrl.id = 'creatDom';
      document.body.appendChild(domUrl);
      domUrl.select(); // 选择对象
      document.execCommand('Copy'); // 执行浏览器复制命令
      let creatDom = document.getElementById('creatDom');
      creatDom.parentNode.removeChild(creatDom);
      this.$message({
        message: '复制成功',
        type: 'success'
      });
    },
    open() {
      this.$alert('<img :rc="imgUrl">', {
        // dangerouslyUseHTMLString: true
      });
      // let url = location.href;
      let url = 'cdsia.org.cn';
      // console.log(url);
      QRCode.toDataURL(url)
        .then(img => {
          // console.log(img);
          //把生成的二维码赋值给上面定义好的imgUrl
          this.imgUrl = img;
        })
        //错误返回信息
        .catch(err => {
          console.error(err);
        });
      // this.qrCode();
    }
  },
  mounted() {
    // 判断图片是否大于857px
    // let imgs = [];
    // let timer = setInterval(() => {
    //   imgs = document.querySelectorAll('.notwz img');
    //   if (imgs.length) {
    //     clearInterval(timer);
    //     imgs.forEach(item => {
    //       if (item.offsetWidth > 857) {
    //         item.style.width = '100%';
    //         item.style.height = 'auto';
    //       }
    //     });
    //   }
    //   setTimeout(() => {
    //     clearInterval(timer);
    //   }, 3000);
    // }, 16.7);
    // let isChange = true;
    // function contimg() {
    //   if (document.documentElement.clientWidth < 970) {
    //     if (isChange) {
    //       imgs.forEach(item => {
    //         item.style.width = '100%';
    //       });
    //       isChange = false;
    //     }
    //   } else {
    //     let imgs = [];
    //     let timer = setInterval(() => {
    //       imgs = document.querySelectorAll('.notwz img');
    //       if (imgs.length) {
    //         clearInterval(timer);
    //         imgs.forEach(item => {
    //           if (item.offsetWidth > 857) {
    //             item.style.width = '90%';
    //             item.style.height = 'auto';
    //           }
    //         });
    //       }
    //       setTimeout(() => {
    //         clearInterval(timer);
    //       }, 3000);
    //     }, 1);
    //     imgs.forEach(item => {
    //       item.style.width = 'initial';
    //     });
    //     isChange = true;
    //   }
    // }
    // const a = setInterval(() => {
    //   contimg();
    // }, 1);
    // setTimeout(() => {
    //   clearInterval(a);
    // }, 5000);
    // window.addEventListener('resize', contimg);
  }
};
</script>
<style lang="stylus" scoped>
.assret > span > a{
  color:#000;
}
.tnottime{
  // color #7a7a7a;
  font-weight: normal;
}
.dsne{
  cursor: pointer
  @media screen and ( max-width:800px) {
    display: none;
  }
}
.notbox
  max-width: 1200px
  margin: 0 auto
  display: flex
  background: #fff
  @media screen and ( max-width:800px) {
    border-top:1px solid #f1f1f1;
  }
  .antboxle
    // max-width: 942px;
    // flex:3
    width:75%;
    @media screen and ( max-width:800px) {
    width:100%;
    }
  .antboxre
    // flex:1;
    width:25%;
    // max-width 258px;
    // min-width: 258px;
    @media screen and (max-width:666px) {
      display: none;
    }
  .notcttit
    text-align: center
    border-bottom: 1px solid  #7a7a7a
    padding-bottom: 15px
    margin: 30px 15px 15px 15px
    // margin-top: 15px
    // padding:0 15px;
  .notcttit >h2
    color:#121212;
    font-size:22px;
    font-weight: normal;
    @media screen and (max-width:800px) {
      font-size: 20px;
      font-weight: 600
    }
  .notcttit >div{
      display: flex;
      justify-content: center
    }
  .notcttit >div >h4
    margin-top:15px
    color:#525252
    font-size: 12px
  .notcttit >div>h4 >span
    margin: 5px;
    display: inline-block
  .notcttit >div >h4 >img
    cursor: pointer
    @media screen and (max-width:800px) {
      display: none;
    }
   .seabrul
    color: #525252
    font-size:15px
    font-family: Microsoft YaHei;
    padding:0 10px 0 20px;
  .seabrul>li
    margin:8px 0
    cursor: pointer;
    list-style: decimal;
  .seabrul >li:hover{
    color:#2ea8e1
  }
  .seabrul>li >a
    text-decoration: none
    color: #7A7A7A

  .asslto
    width:100%
    border-bottom: 1px solid #dadada
    .asslto
    >span
      font-size:20px
      padding:0 8px
      color:#346CC6
      border-bottom:2px solid #346CC6
      margin-top:30px
      display: inline-block;
      padding-bottom: 5px;
  .notwz
    width: 100%;
    color: #525252;
    // min-height: 700px;
    // line-height: 1.5rem;
    margin-bottom: 50px;
    padding: 0 25px;
    box-sizing: border-box;
    @media screen and (max-width:800px) {
       width:93%
    }
    @media screen and (max-width:666px) {
      width:100%
    }
  .notwz::selection{
    background: #1d5597;
	  color:#fff;
  }
  .notwz >ul
    width:100%;
  .notwz >p
    width:100%;
    color: #000
  .notwz >p > img
    width:80% !important;

  .wzbl
    color:#346CC6

.assreboxo
    width:100%;
    margin:0 auto;
    font-family: PingFang SC-Regular
    font-size: 16px;
    // background: #fff
    padding:15px;
    // @media screen and (max-width:800px) {
    //   display: none;
    // }
    .assreboxo
    >span
      color: #1a1a1a
      cursor: pointer;

.files {
  padding: 0 25px;
}
.files a {
    color: #346CC6;
    cursor: pointer;
}
.files a:hover {
    color: #214785;
}
.files a:visited {
    color: #346CC6;
}
</style>
<style>
pre {
  white-space: pre-wrap;
}
.notwz > p > img {
  max-width: 100%;
  object-fit: scale-down;
  display: block;
}
.notwz > p > span > img {
  max-width: 100%;
  object-fit: scale-down;
  display: block;
}
.ql-align-center > img {
  margin: 0 auto;
}
</style>
