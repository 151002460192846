<template>
  <div id="app" :class="{ grayscale: ebo }">
    <navigation></navigation>
    <router-view></router-view>
    <fter></fter>
  </div>
</template>

<script>
import Navigation from '@/components/index/navigation.vue';
import fter from '@/components/index/fter.vue';
import { detail } from '@/api/index.js';

export default {
  name: 'Home',
  data() {
    return {
      hfliat: [],
      ebo: false
    };
  },
  //声明传递过来的子组件
  components: {
    Navigation,
    fter
  },
  created() {
    this.handleheadfoot();
  },
  mounted: function () {
    // let yy = new Date().getFullYear();
    // console.log(yy);
  },
  methods: {
    //头部底部数据
    handleheadfoot() {
      detail(2).then(res => {
        this.hfliat = JSON.parse(res.data.data.config);
        this.ebo = this.hfliat.ebo;
        // console.log(this.hfliat);
      });
    }
  }
};
</script>
<style> @import './styles/quill.font.css'; </style>
<style lang="stylus">
#app
	background #fff
  font-family:Microsoft YaHei, snas, serif, monospace
a{
 text-decoration: none;
}
.grayscale {
  filter: grayscale(100%);
}
// 单行显示
.one-line{
  word-break:break-all;
  white-space:nowrap;
  text-overflow:ellipsis;
  overflow:hidden;
}
.lbone-line{
  word-break:break-all;
  text-overflow:ellipsis;
  overflow:hidden;
}
// 显示多行
.three-line{
  word-break:break-all;
  display: -webkit-box;
 -webkit-box-orient:vertical;
  overflow:hidden;
  -webkit-line-clamp: 3
}
.two-line{
  word-break:break-all;
 display: -webkit-box;
 -webkit-box-orient:vertical;
  overflow:hidden;
  -webkit-line-clamp: 2
}
</style>
